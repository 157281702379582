import React from 'react';
import clsx from "clsx";

export function FramedBlock({children, className, center = true, selectable = false, nopadding = false}: FramedBlockProps) {
    return (
        <div
            className={clsx("overflow-hidden w-full sm:text-left flex flex-col sm:flex-row justify-between items-center border border-gray-200 rounded-md bg-white text-xl", center && 'text-center', className, !nopadding && 'py-4 px-6', selectable && 'group hover:border-go-primary')}>
            {children}
        </div>
    );
}

export interface FramedBlockProps {
    children?: any;
    className?: string;
    nopadding?: boolean;
    selectable?: boolean;
    center?: boolean;
}

export default FramedBlock;
