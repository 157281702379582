import React, {useMemo} from "react";
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import DisplayBookpaymentGameForm from "../molecules/DisplayBookpaymentGameForm";

export const BookpaymentPublicPage = editablePage('game', [], ({staticContext: {bookpayment, game, locale, mode}}: any) => {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);

    return (
        <GamePageWrapper game={game} locale={locale}>
            <TypePage type={'game'} doc={game} page={'bookpayment_public'} locale={locale}
                      publicPages={publicPages} templates={templates}
                      pixels={pixel} pushs={push}
                      pixelProps={pixelProps} pushProps={pushProps}
                      noindex
            >
                <DisplayBookpaymentGameForm game={game} bookpayment={bookpayment} />
                <Pixel app={pixel.app} event={pixel.pages.bookpayment_public} gc={game.code} />
            </TypePage>
        </GamePageWrapper>
    );
});

export default BookpaymentPublicPage;
