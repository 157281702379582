import * as pages from './pages';

const routeMap = {
    promise_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'promise_', ssr: 'promise_public', component: pages.promise_public},
];

export default {configure: () => ({routeMap, routeList, pages})};