export default {
    game_buy_quantity_page: { common: { template: 'dynamic_game_public' } },
    game_buy_custom_quantity_page: { common: { template: 'dynamic_game_public' } },
    game_buy_owner_page: { common: { template: 'dynamic_game_public' } },
    game_buy_bonus_page: { common: { template: 'dynamic_game_public' } },
    game_buy_payment_page: { common: { template: 'dynamic_game_public' } },
    game_buy_beneficiary_page: { common: { template: 'dynamic_game_public' } },
    game_buy_project_page: { common: { template: 'dynamic_game_public' } },
    game_buy_request_page: { common: { template: 'dynamic_game_public' } },
    game_buy_privacy_page: { common: { template: 'dynamic_game_public' } },
    game_buy_donation_page: { common: { template: 'dynamic_game_public' } },
    game_buy_custom_page: { common: { template: 'dynamic_game_public' } },
    game_buy_sellergroup_groupings_page: { common: { template: 'dynamic_game_public' } },
    game_buy_sellergroup_page: { common: { template: 'dynamic_game_public' } },
    game_buy_seller_page: { common: { template: 'dynamic_game_public' } },
    game_buy_external_payment_page: { common: { template: 'dynamic_game_public' } },
};
