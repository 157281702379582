import * as pages from './pages';

const routeMap = {
    preorder_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'preorder_', ssr: 'preorder_public', component: pages.preorder_public},
];

export default {configure: () => ({routeMap, routeList, pages})};