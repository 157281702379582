import * as pages from './pages';

const routeMap = {
    error_country_home: {
        en_US: '/:country',
    },
    error_error_code: {
        en_US: '/:country/e/:code',
    },
}

const routeList = [
    'error_country_home',
    'error_error_code',
];

export default {configure: () => ({routeMap, routeList, pages})};