import React from "react";
import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import useGameBuyBookPayments from "../../../hooks/useGameBuyBookPayments";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function PaymentBuyBookGameForm({ game, book, workflows }: PaymentBuyBookGameFormProps) {
    const {t} = useTypeTranslation('game', game);

    const [items] = useGameBuyBookPayments(book, game, { t, workflows });

    return (
        <ChoiceMenu items={items} />
    );
}

export interface PaymentBuyBookGameFormProps {
    game: any;
    book: any;
    workflows: any;
}

export default PaymentBuyBookGameForm;
