import * as pages from './pages';

const routeMap = {
    sellergroup_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'sellergroup_', ssr: 'sellergroup_public', component: pages.sellergroup_public},
];

export default {configure: () => ({routeMap, routeList, pages})};