import React from "react";
import Text from "@gotombola/react-ds/lib/atoms/Text";
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function DisplayPreorderGameForm({ game, preorder }: DisplayPreorderGameFormProps) {
    const {t} = useTypeTranslation('game', game);

    return (
        <>
            {'CREATED' === preorder.status && <Text>{t('preorder_created_page_text', {amount: formatRawAmount(preorder?.paymentAmount, preorder?.paymentCurrency)})}</Text>}
            {'ACCEPTED' === preorder.status && <Text>{t('preorder_accepted_page_text')}</Text>}
            {'REFUSED' === preorder.status && <Text>{t('preorder_refused_page_text')}</Text>}
            {'CANCELED' === preorder.status && <Text>{t('preorder_canceled_page_text')}</Text>}
            {'CONVERTED' === preorder.status && <Text>{t('preorder_converted_page_text')}</Text>}
            {'EXPIRED' === preorder.status && <Text>{t('preorder_expired_page_text')}</Text>}
        </>
    );
}

export interface DisplayPreorderGameFormProps {
    game: any;
    preorder: any;
}

export default DisplayPreorderGameForm;
