import React from 'react';
import buildAttributionLabel from "@gotombola/react-ds/lib/utils/buildAttributionLabel";
import {Game, Sale} from "@gotombola/sdk";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function SaleAttributions({game, sale}: SaleAttributionsProps) {
    const {t} = useTypeTranslation('game', game);

    return (
        <>
            {(sale.attributions || []).map((attribution: any, index: number) => {
                try {
                    const attr = JSON.parse(attribution);
                    if (attr.type !== 'person' && attr.type !== 'animal' && attr.type !== 'pet') return null;
                    return (
                        <div key={index} className={"w-full text-center sm:text-left flex flex-col sm:flex-row justify-between items-center border border-gray-200 rounded-md py-4 px-6 bg-white text-xl"}>
                            <div className={'flex flex-col h-full'}>
                                <div className={"font-go-text text-2xl text-go-title"}>{t('sale_public_attribution_thanks', {name: buildAttributionLabel(attr, {t})})}</div>
                                <div className={"font-go-text text-go-text text-xl mt-4"}>{t('sale_public_attribution_sponsor', {organization: sale.organizationName})}</div>
                            </div>
                        </div>
                    )
                } catch (e) {
                    return null;
                }
            })}
        </>
    );
}

export interface SaleAttributionsProps {
    game: Game & Record<string, any>;
    sale: Sale & Record<string, any>;
}

export default SaleAttributions;