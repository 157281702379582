import React, {useMemo} from 'react'
import {useMode} from "../../../utils/common";
import TypePage from '@gotombola/react-ds/lib/pages/TypePage';
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";

const DoneePublicPage = editablePage('donee', [], ({staticContext: {donee, locale, mode}}: any) => {
    const {pixel, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({sc: donee.code}), [donee.code]);

    return (
        <TypePage type={'donee'} doc={donee} page={'public'} locale={locale}
                  publicPages={publicPages} templates={templates}
                  pixels={pixel} pixelProps={pixelProps}
        />
    );
});

export default DoneePublicPage;