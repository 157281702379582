export default {
    sale__default: {
        steps: {
            start: {redirect: 'quantity'},
            quantity: {url: 'game_buy_quantity', next: 'donation'},
            custom_quantity: {url: 'game_buy_custom_quantity', next: 'donation', if: ({game}: any) => ((game.features || []).includes('custom_ticket_quantity'))},
            donation: {url: 'game_buy_donation', next: 'seller_group_groupings', if: ({game, query}: any) => ((game.features || []).includes('donation_only_allowed') && 0 === query.get('q'))},
            seller_group_groupings: {url: 'game_buy_seller_group_groupings', next: {default: 'seller_group', alone: 'seller', none: 'seller'}, if: ({game, query}: any) => (!(game.features || []).includes('hide_sellergroups') && (((game.sellergroups || {}).items || {}).length > 20) && !game?.sellergroupGroupingsDisabled && !query.get('sg'))},
            seller_group: {url: 'game_buy_seller_group', next: 'seller', if: ({game, query}: any) => (!(game.features || []).includes('hide_sellergroups') && (((game.sellergroups || {}).items || {}).length) && !query.get('sg'))},
            seller: {url: 'game_buy_seller', next: 'beneficiary', if: ({game, query}: any) => (!(game.features || []).includes('hide_sellers') && (((game.sellergroups || {}).items || {}).length) && (!query.get('sg') || !!game.sellergroups.items.find((x: any) => x.code === query.get('sg'))) && !!game.statSellers)},
            beneficiary: {url: 'game_buy_beneficiary', next: 'project', if: ({game}: any) => ((game.beneficiaries || {}).items || {}).length > 1},
            project: {url: 'game_buy_project', next: 'owner', if: ({game}: any) => ((game.projects || {}).items || {}).length},
            owner: {url: 'game_buy_owner', next: 'custom'},
            custom: {url: 'game_buy_custom', next: 'bonus', if: ({game}: any) => ((game.inputs || {}).items || {}).length},
            bonus: {url: 'game_buy_bonus', next: 'privacy', if: ({game, query}: any) => '0' !== game.customBonuses && !query.get('d') && !!game?.features?.includes('bonus')},
            privacy: {url: 'game_buy_privacy', next: 'payment', if: ({game}: any) => (game?.privacyShield === 'rgpd')},
            payment: {url: 'game_buy_payment', next: 'external_payment'},
            external_payment: {url: 'game_buy_external_payment'},
        },
    },
    sale__light: {
        steps: {
            start: {redirect: 'quantity'},
            quantity: {url: 'game_buy_quantity', next: 'owner'},
            owner: {url: 'game_buy_owner', next: 'payment'},
            payment: {url: 'game_buy_payment', next: 'external_payment'},
            external_payment: {url: 'game_buy_external_payment'},
        },
    },
};
