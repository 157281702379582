import * as pages from './pages';

const routeMap = {
    bookpayment_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'bookpayment_', ssr: 'bookpayment_public', component: pages.bookpayment_public},
];

export default {configure: () => ({routeMap, routeList, pages})};