import React from 'react';
import {Game, Sale} from "@gotombola/sdk";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import FramedBlock from "./FramedBlock";
import useTypeUrl from "@gotombola/react-ds/lib/hooks/useTypeUrl";
import RichText from "@gotombola/react-ds/lib/atoms/RichText";

export function SaleSharePushPanel({game, sale}: SaleSharePushPanelProps) {
    const {t} = useTypeTranslation('game', game);
    const shareUrl = useTypeUrl('game', game, 'share', `z=${sale.ownerGodfatherCode}`);

    if (game?.features?.includes('hide_godfather_share')) {
        return null;
    }

    return (
        <FramedBlock selectable center={false} className={"text-justify font-go-text text-go-text text-xl cursor-pointer group hover:bg-go-primary hover:text-white"}>
            <a href={shareUrl}>
                <div className={'text-justify w-full'}>
                    <RichText value={t('sale_public_page_godfather_advice')} />
                </div>
            </a>
        </FramedBlock>
    );
}

export interface SaleSharePushPanelProps {
    game: Game & Record<string, any>;
    sale: Sale & Record<string, any>;
}

export default SaleSharePushPanel;
