import React from "react";
import TextField from "@gotombola/react-ds/lib/atoms/fields/TextField";
import FileUploader from "@gotombola/react-ds/lib/molecules/FileUploader";
import usePageTypeTranslation from "@gotombola/react-ds/lib/hooks/usePageTypeTranslation";

export function InboxGameForm({ game }: InboxGameFormProps) {
    const {t} = usePageTypeTranslation('inbox', 'game', game);

    return (
        <div className={'w-full items-center flex flex-col px-4 py-8 sm:py-16 space-y-8'}>
            <div className={'w-full'}>
                <FileUploader title={t('uploader_logos_title')} description={t('uploader_logos_description')}/>
            </div>
            <div className={'w-full'}>
                <FileUploader title={t('uploader_images_title')} description={t('uploader_images_description')}/>
            </div>
            <div className={'w-full'}>
                <FileUploader title={t('uploader_bunches_title')} description={t('uploader_bunches_description')}/>
            </div>
            <div className={'w-full'}>
                <FileUploader title={t('uploader_sponsors_title')} description={t('uploader_sponsors_description')}/>
            </div>
            <div className={'flex flex-col items-center w-full md:w-1/2 rounded-lg'}>
                <div
                    className={'font-go-text text-go-text tracking-tight mb-2 font-bold sm:text-xl'}>{t('uploader_text_title')}</div>
                <div
                    className={'font-go-text text-go-text tracking-tight mb-2 font-light'}>{t('uploader_text_description')} </div>
                <TextField rows={10} className={'w-full'}/>
                <div className={'flex pt-4 justify-center'}>
                    <button onClick={() => alert('Envoyé')}
                            className={"font-go-button sm:text-lg flex md:w-full justify-center tracking-tight text-white leading-relaxed cursor-pointer bg-blue-500 hover:bg-opacity-90 border-2 px-4 py-2 hover:shadow-sm font-medium rounded-md"}>
                        {t('uploader_btn_submit_label')}
                    </button>
                </div>
            </div>
            <div className={'flex-1 w-full'}>
                <FileUploader title={t('uploader_other_title')} description={t('uploader_other_description')}/>
            </div>
        </div>
    );
}

export interface InboxGameFormProps {
    game: any;
    workflows: any;
}

export default InboxGameForm;
