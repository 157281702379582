import React, {useEffect, useState} from "react";
import Text from "@gotombola/react-ds/lib/atoms/Text";
import Button from "@gotombola/react-ds/lib/atoms/Button";
import useTypeUrl from "@gotombola/react-ds/lib/hooks/useTypeUrl";
import SectionTitle from "@gotombola/react-ds/lib/atoms/SectionTitle";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function DisplayTicketBookGameForm({ game, book }: DisplayTicketBookGameFormProps) {
    const [ticket, setTicket] = useState("");
    const { t } = useTypeTranslation('game', game);
    const extraBuyQs = `sg=${book?.sellergroupCode}&se=${book?.sellerCode}&kr=${book?.customCode}&ticket=${ticket}`
    const qs = useQueryString().toString();
    const buyUrl = useTypeUrl('game', game, 'buy', !!extraBuyQs ? `${qs}&${extraBuyQs}` : qs);

    useEffect(() => {
        setTicket((new URLSearchParams(window?.location?.search)).get('ticket') as string);
    }, [])

    return (
        <div className='flex flex-col justify-center m-10'>
            <div className='flex flex-col justify-around items-center gap-6 mb-6 h-[50vh]'>
                <SectionTitle title={t('book_ticket_public_page_title', {ticket: ticket})} level={1}/>
                <div className='flex flex-col gap-2 items-center'>
                    <Text center className={'text-xl'}>{t('book_ticket_public_page_subtitle')}</Text>
                    <Text center className={'text-xl'}>{t('book_ticket_public_page_subtitle_payment')}</Text>
                    <Text center className={'text-xl'}>{t('book_ticket_public_page_subtitle_numerical')}</Text>
                </div>
                <Button target={buyUrl} label={t("book_ticket_public_page_btn_label_buy")}/>
            </div>
            <div>
                <div className={"flex flex-col space-y-4"}>
                    <div className={"border border-gray-200 bg-white rounded-md"}>
                        <div
                            className={"flex flex-row justify-between items-center p-6 bg-gray-50 border-b"}>
                            <div
                                className={'font-go-text text-xl font-bold text-gray-600'}>{t('book_ticket_public_page_table_title')}</div>
                        </div>
                        <div className={"space-y-4"}>
                            <div className={"border-b border-gray-200 px-6 py-4 flex justify-between items-center"}>
                                <Text
                                    className={"text-lg text-gray-500"}>{t("book_ticket_public_page_table_book")}</Text>
                                <Text className={"text-lg text-gray-800 text-right"}>{book?.customCode}</Text>
                            </div>
                            <div className={"border-b border-gray-200 px-6 py-4 flex justify-between items-center"}>
                                <Text
                                    className={"text-lg text-gray-500"}>{t("book_ticket_public_page_table_ticket")}</Text>
                                <Text className={"text-lg text-gray-800 text-right"}>{ticket}</Text>
                            </div>
                            <div className={"border-b border-gray-200 px-6 pb-4 flex justify-between items-center"}>
                                <Text
                                    className={"text-lg text-gray-500"}>{t([`book_ticket_public_page_table_sellergroupname_${game?.domain || ''}`, "book_ticket_public_page_table_sellergroupname"])}</Text>
                                <Text className={"text-lg text-gray-800 text-right"}>{book?.sellergroupName}</Text>
                            </div>
                            <div className={"border-b border-gray-200 px-6 pb-4 flex justify-between items-center"}>
                                <Text
                                    className={"text-lg text-gray-500"}>{t("book_ticket_public_page_table_seller_identity")}</Text>
                                <Text
                                    className={"text-lg text-gray-800 text-right"}>{`${book?.sellerFirstName} ${book?.sellerLastName?.slice(0, 1)}.`}</Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export interface DisplayTicketBookGameFormProps {
    game: any;
    book: any;
}

export default DisplayTicketBookGameForm;