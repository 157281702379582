import SponsorsTypeModule from "@gotombola/react-ds/lib/template-modules/type/SponsorsTypeModule";
import React from "react";
import {Game, Presult} from "@gotombola/sdk";

export function PresultSponsors({game}: PresultSponsorsProps) {
    return (
        <SponsorsTypeModule typeType={'game'} doc={game} />
    );
}

export interface PresultSponsorsProps {
    presult: Presult;
    game: Game;
}

export default PresultSponsors;