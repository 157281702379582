export default {
    bookpayment__default: {
        steps: {
            start: {redirect: 'owner'},
            owner: {url: 'game_buy_book_owner', next: 'bonus'},
            bonus: {url: 'game_buy_book_bonus', next: 'payment', if: ({game}: any) => !!game?.features?.includes('bookpayment_donation') && ('0' !== game.customBonuses)},
            payment: {url: 'game_buy_book_payment', next: 'external_payment'},
            external_payment: {url: 'game_buy_book_external_payment'},
        },
    },
};
