import React, {useMemo} from 'react';
import StaticIcon from "@gotombola/react-ds/lib/atoms/StaticIcon";
import QRCodeDownload from "@gotombola/react-ds/lib/molecules/QRCodeDownload";
import {Game, Sale} from "@gotombola/sdk";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function SaleOwnedTickets({game, sale}: SaleOwnedTicketsProps) {
    const {t} = useTypeTranslation('game', game);

    const tickets = useMemo(() => ((sale.ownedTickets || {}).items || []), [sale.ownedTickets]);

    if (!tickets?.length) return null;

    return (
        <div className={"flex flex-col space-y-12 w-full"}>
            <div className={"flex flex-col sm:flex-row sm:flex-wrap gap-4 justify-between w-full"}>
                {tickets.map((ticket: any) => {
                    return (
                        <div key={`ticket-${ticket.id}`} className={"bg-white flex flex-col items-center rounded-md border sm:border-0 border-gray-200 p-2 sm:p-0"}>
                            <div className={"w-full flex justify-between"}>
                                <div className={"flex"}>
                                    <div
                                        className={"w-12 h-12 bg-blue-100 rounded-full flex justify-center items-center"}>
                                        <StaticIcon type={'ticket'} className={"text-blue-500"} />
                                    </div>
                                    <div className={"flex ml-2 items-center space-x-1"}>
                                        <span className={"font-go-text text-go-text text-xl text-gray-500 sm:hidden md:inline"}>{t('sale_public_page_ticket')}</span>
                                        <span className={"font-go-text text-go-text text-xl"}>{ticket.code}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"hidden sm:flex w-full items-center justify-center m-2"}>
                                <QRCodeDownload
                                    lazy={false}
                                    qrCodeClassName={'w-48 h-48 mx-auto'}
                                    target={ticket.publicPageUrl}
                                    filename={`${game?.code}-qrcode`}
                                    style={game?.tenantCode}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export interface SaleOwnedTicketsProps {
    game: Game & Record<string, any>;
    sale: Sale & Record<string, any>;
}

export default SaleOwnedTickets;