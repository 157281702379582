import React from "react";
import clsx from "clsx";
import Text from "@gotombola/react-ds/lib/atoms/Text";
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

const statusClasses: any = {
    CREATED: {
        tag: 'bg-gray-300 text-black',
    },
    PREORDERED: {
        tag: 'bg-blue-400 text-white',
    },
    PROMISED: {
        tag: 'bg-purple-400 text-white',
    },
    PAID: {
        tag: 'bg-green-400 text-white',
    },
    CANCELED: {
        tag: 'bg-red-400 text-white',
    },
    FAILED: {
        tag: 'bg-red-400 text-white',
    },
}
export function DisplayBookpaymentGameForm({ game, bookpayment }: DisplayBookpaymentGameFormProps) {
    const {t} = useTypeTranslation('game', game);
    const date = new Date(parseInt(bookpayment.createdAt));

    return (
        <div className={'w-full sm:w-3/4 md:w-2/3 mt-16 sm:mt-32 mx-auto'}>

            <div className={clsx("flex flex-col w-full px-4 sm:px-0 space-y-6 sm:space-y-14 mb-20")}>
                <div
                    className={"w-full text-center sm:text-left flex flex-col sm:flex-row justify-between items-center border border-gray-200 rounded-md py-4 px-6 bg-white"}>
                    <div className={'flex sm:w-2/3 flex-col h-full'}>
                        <Text
                            className={"text-2xl text-green-600"}>{t('book_public_page_thank', {price: formatRawAmount(bookpayment.paymentAmount, bookpayment.paymentCurrency)})}</Text>
                        <Text className={"text-xl mt-4"}>{t('book_public_page_spam_advice')}</Text>
                    </div>
                    <div
                        className={'flex flex-col w-2/3 sm:w-1/3 justify-items-end sm:justify-end space-y-4 md:pl-8 pt-2 sm:pt-0'}>
                        <a href={game.publicPageShortUrl}
                           className={"font-go-button text-center bg-go-secondary text-white cursor-pointer font-bold text-xl hover:bg-go-primary p-2 rounded-md"}>
                            {t('book_public_page_go_game_btn_label')}
                        </a>
                    </div>
                </div>
                <div className={"flex flex-col space-y-4"}>
                    <div className={"border border-gray-200 bg-white rounded-md"}>
                        <div
                            className={"flex flex-row justify-between items-center p-6 bg-gray-50 border-b"}>
                            <div
                                className={'font-go-text text-xl font-bold text-gray-600'}>{t('book_public_page_details')}</div>
                        </div>
                        <div className={"space-y-4"}>
                            <div className={"border-b border-gray-200 px-6 py-4 flex justify-between items-center"}>
                                <Text className={"text-lg text-gray-500"}>{t('book_public_page_status')}</Text>
                                <div className={"text-gray-800 text-right"}>
                                    <Text inline basic
                                          className={clsx('p-2 rounded', statusClasses[bookpayment.status]?.tag)}>{t([`bookpayment_status_${(bookpayment.status || '').toLowerCase()}`, bookpayment.status])}</Text>
                                </div>
                            </div>
                            <div className={"border-b border-gray-200 px-6 pb-4 flex justify-between items-center"}>
                                <Text className={"text-lg text-gray-500"}>{t('book_public_page_ref')}</Text>
                                <Text className={"text-lg text-gray-800 text-right"}>{bookpayment.id}</Text>
                            </div>
                            <div className={"border-b border-gray-200 px-6 pb-4 flex justify-between items-center"}>
                                <Text className={"text-lg text-gray-500"}>{t('book_public_page_amount')}</Text>
                                <Text
                                    className={"text-lg text-gray-800 text-right"}>{formatRawAmount(bookpayment.paymentAmount, bookpayment.paymentCurrency)}</Text>
                            </div>
                            <div className={"border-b border-gray-200 px-6 pb-4 flex justify-between items-center"}>
                                <Text className={"text-lg text-gray-500"}>{t('book_public_page_date')}</Text>
                                <Text className={"text-lg text-gray-800 text-right"}>{date.toLocaleDateString("fr")}</Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export interface DisplayBookpaymentGameFormProps {
    game: any;
    bookpayment: any;
}

export default DisplayBookpaymentGameForm;
