import React, { useMemo} from 'react'
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import PaymentPromiseGameForm from "../molecules/PaymentPromiseGameForm";

const PromisePublicPage = editablePage('game', [], ({staticContext: {game, promise, locale, mode}}: any) => {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code, pi: promise.id}), [game.code, promise.id]);
    const pushProps = useMemo(() => ({mode: game.status, game, promise}), [game, game.status, promise]);

    return (
        <GamePageWrapper game={game} locale={locale}>
            <TypePage type={'game'} doc={game} page={'promise_public'} locale={locale}
                      publicPages={publicPages} templates={templates}
                      pixels={pixel} pushs={push}
                      pixelProps={pixelProps} pushProps={pushProps}
                      noindex
            >
                <PaymentPromiseGameForm game={game} promise={promise} />
                <Pixel app={pixel.app} event={pixel.pages.promise_public} />
            </TypePage>
        </GamePageWrapper>
    );
});

export default PromisePublicPage;
