import React from "react";
import useGameBuyDonations from "../../../hooks/useGameBuyDonations";
import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function DonationBuyGameForm({ game, workflows }: DonationBuyGameFormProps) {
    const {t} = useTypeTranslation('game', game);

    const items = useGameBuyDonations(game, { t, workflows });

    return (
        <ChoiceMenu items={items} />
    );
}

export interface DonationBuyGameFormProps {
    game: any;
    workflows: any;
}

export default DonationBuyGameForm;
