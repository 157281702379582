const routeMap = {
    redirect: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'redirect', path: '/', exact: false, redirect: ({req}: any, options: any) => options.redirect ? {destination: `${options.redirect}${req.url}`} : {destination: '/'}},
];

export default {configure: () => ({routeMap, routeList})};