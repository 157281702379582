import React from "react";
import Text from "@gotombola/react-ds/lib/atoms/Text";
import QRCode from "@gotombola/react-ds/lib/atoms/QRCode";
import getOwnerType from "@gotombola/react-ds/lib/utils/getOwnerType";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";

export function RequestBuyGameForm(_: RequestBuyGameFormProps) {
    const query = useQueryString();
    const owner = query.get('o');
    const ownerType = getOwnerType(owner);

    return (
        <div className={'w-full space-y-4'}>
            {!!owner && <Text>{`Acheteur : ${owner}`}</Text>}
            <QRCode className={'w-60'} target={"https://www.google.fr"} />
            {owner && (
                <>
                    <p className={'font-go-text text-go-text mt-2 mb-6 text-2xl'}>ou</p>
                    <button
                        type={"button"}
                        onClick={() => {}}
                        className={"font-go-button w-full p-3 bg-go-secondary hover:bg-go-primary disabled:bg-gray-300 rounded-sm text-xl text-white"}
                    >
                        Envoyer une demande {ownerType}
                    </button>
                </>
            )}
        </div>
    );
}

export interface RequestBuyGameFormProps {
    game: any;
    workflows: any;
}

export default RequestBuyGameForm;
