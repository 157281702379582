import React, {useCallback, useMemo} from 'react'
import {useMode} from "../../../utils/common";
import TypePage from '@gotombola/react-ds/lib/pages/TypePage';

export default function PartnerRegistrationOwnerPage({staticContext: {partner, registration, locale, mode}}: any) {
    const {pixel, push, publicPages, templates, workflows, actions} = useMode(mode);

    const pixelProps = useMemo(() => ({pc: partner.code}), [partner.code]);
    const pushProps = useMemo(() => ({mode: partner.status, partner}), [partner, partner.status]);

    const extraFn = useCallback(() => ({registration}), [registration]);

    return (
        <TypePage type={'partner'} doc={partner} page={'registration_owner'} locale={locale}
                  publicPages={publicPages} templates={templates}
                  pixels={pixel} pushs={push}
                  pixelProps={pixelProps} pushProps={pushProps}
                  workflows={workflows} actions={actions}
                  extraFn={extraFn}
        />
    );
}