import * as pages from './pages';

const routeMap = {
    partner_: {
        en_US: '/',
    },
    partner_registration_organization: {
        en_US: '/registrations/:id/:publicToken/organization',
        fr_FR: '/inscriptions/:id/:publicToken/organisme',
    },
    partner_registration_owner: {
        en_US: '/registrations/:id/:publicToken/owner',
        fr_FR: '/inscriptions/:id/:publicToken/identite',
    },
    partner_registration_game: {
        en_US: '/registrations/:id/:publicToken/game',
        fr_FR: '/inscriptions/:id/:publicToken/collecte',
    },
    partner_registration_summary: {
        en_US: '/registrations/:id/:publicToken/summary',
        fr_FR: '/inscriptions/:id/:publicToken/resume',
    },
    partner_register_website: {
        en_US: '/register/website',
        fr_FR: '/inscription/website',
    },
    partner_registration: {
        en_US: '/registrations/:id/:publicToken',
        fr_FR: '/inscriptions/:id/:publicToken',
    },
    partner_register: {
        en_US: '/register',
        fr_FR: '/inscription',
    },
}

const routeList = [
    'partner_registration_organization',
    'partner_registration_owner',
    'partner_registration_game',
    'partner_registration_summary',
    'partner_register_website',
    'partner_registration',
    'partner_register',
    {name: 'partner_', ssr: 'partner_public', component: pages.partner_public},
];

export default {configure: () => ({routeMap, routeList, pages})};