import modules from './modules';
import pixel from './pixel';
import publicPages from './public-pages';
import push from './push';
import routes from './routes';
import templateDefinitions from './template-definitions';
import workflows from "./workflows";

export default {
    modules,
    pixel,
    publicPages,
    push,
    routes,
    templateDefinitions,
    workflows,
}