import React from "react";
import useGameProjects from "../../../hooks/useGameProjects";
import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function ProjectBuyGameForm({ game, workflows }: ProjectBuyGameFormProps) {
    const {t} = useTypeTranslation('game', game);

    const items = useGameProjects(game, { t, workflows });

    return (
        <ChoiceMenu items={items} />
    );
}

export interface ProjectBuyGameFormProps {
    game: any;
    workflows: any;
}

export default ProjectBuyGameForm;
