import * as pages from './pages';

const routeMap = {
    sponsor_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'sponsor_', ssr: 'sponsor_public', component: pages.sponsor_public},
];

export default {configure: () => ({routeMap, routeList, pages})};