import React, {useMemo} from 'react'
import {useMode} from "../../../utils/common";
import TypePage from '@gotombola/react-ds/lib/pages/TypePage';
import buildTicketPriceCurrencyFromCampaign from "@gotombola/react-ds/lib/utils/buildTicketPriceCurrencyFromCampaign";

function extraFn({doc}: any) {
    return {offerCode: doc.offerCode, ticketPriceCurrency: buildTicketPriceCurrencyFromCampaign(doc)};
}

export default function CampaignPublicPage({staticContext: {campaign, locale, mode}}: any) {
    const {modules, pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({cc: campaign.code}), [campaign.code]);
    const pushProps = useMemo(() => ({mode: campaign.status, campaign}), [campaign, campaign.status]);

    return (
        <TypePage type={'campaign'} doc={campaign} page={'public'} layout={'central_box'} locale={locale}
                  publicPages={publicPages} templates={templates}
                  pixels={pixel} pushs={push}
                  pixelProps={pixelProps} pushProps={pushProps} customModes={modules.campaign.custom}
                  extraFn={extraFn}
        />
    );
}
