import React from 'react';
import Ads from "../../../atoms/Ads";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';

export function SalePublicPageBodyAdZone({ads}: SalePublicPageBodyAdZoneProps) {
    const {game} = useGameContext();
    const {t} = useTypeTranslation('game', game);

    if (!ads?.length) return null;

    return (
        <div className={'w-full flex flex-col gap-6 pb-4'}>
            <div className={'w-full text-center font-light bg-gray-100 rounded-md p-2'}>
                <p className={'font-go-text text-go-text font-bold'}>{t('sale_public_page_body_ad_zone_text')}</p>
                <p className={'font-go-text text-go-text'}>{t('sale_public_page_body_ad_zone_text2')}</p>
            </div>
            <Ads ads={ads} format={'image'} className={'w-full flex flex-col gap-4'} />
        </div>
    );
}

export interface SalePublicPageBodyAdZoneProps {
    ads?: any[];
}

export default SalePublicPageBodyAdZone;