import * as pages from './pages';

const routeMap = {
    home: {
        en_US: '/',
    },
}

const routeList = [
    'home',
];

export default {configure: () => ({routeMap, routeList, pages})};