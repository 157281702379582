import React, {useMemo} from "react";
import {applySort} from "../../../utils/sort";
import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";

export function SellergroupGroupingBuyGameForm({ game, workflows }: SellergroupGroupingBuyGameFormProps) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const {t} = useTypeTranslation('game', game);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'seller_group_groupings', {game});
    const rawSellergroups: any[] = (game.sellergroups && game.sellergroups.items) || [];
    const gameDomain = game.domain || undefined;
    const groupings = useMemo(() => {
        return [...rawSellergroups].reduce((acc: any, sellergroup: any) => {
            const label = sellergroup.name.split(/[,.\s]/)[0];
            const found = acc.find((s: any) => s.label === label);
            const choice = {
                id: sellergroup.code,
                label: sellergroup.name,
                rank: sellergroup.rank,
            };
            if (!found) {
                acc.push({
                    id: `grp${label}`,
                    label: label,
                    rank: sellergroup.rank,
                    children: [choice],
                });
            } else {
                found.children.push(choice);
            }
            return acc;
        }, []);
    }, [rawSellergroups, query, game, nextStep]);
    const items = useMemo(() => {
        const its = [...groupings].reduce((acc: any, grouping: any) => {
            if (grouping.children.length > 1) {
                query.set('sgg', grouping.label);
                grouping.target = buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder);
                acc.push(grouping);
                query.delete('sgg');
            } else {
                query.set('sg', grouping.children[0].id);
                grouping.children[0].target = buildGameNextStepUrl(game, nextStep, query, 'alone', undefined, urlBuilder);
                acc.push(grouping.children[0]);
                query.delete('sg');
            }
            return acc;
        }, []);
        applySort(its, game?.sellergroupSortMode, 'default');
        its.push({
            id: null,
            target: buildGameNextStepUrl(game, nextStep, query, 'none', undefined, urlBuilder),
            label:  t([
                `game_buy_seller_group_other_choice_common_${game?.sellergroupType}`,
                `game_buy_seller_group_other_choice_${gameDomain}`,
                `game_buy_seller_group_other_choice_generic`,
            ]),
        });
        return its;
    }, [game, nextStep, query, gameDomain, groupings, urlBuilder]);

    return (
        <ChoiceMenu items={items} />
    );
}

export interface SellergroupGroupingBuyGameFormProps {
    game: any;
    workflows: any;
}

export default SellergroupGroupingBuyGameForm;
