export default {
    game_buy_book_payment: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_book_payment_title",
                },
                "common_message": {
                    "attribute": "buyPaymentMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_book_external_payment: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_book_external_payment_title",
                },
                "common_message": {
                    "attribute": "buyExternalPaymentMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_book_bonus: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_book_bonus_title",
                },
                "common_message": {
                    "attribute": ["bonusMessage", "buyBonusMessage"],
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_book_owner: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_book_owner_title",
                },
                "common_message": {
                    "attribute": "buyOwnerMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
}