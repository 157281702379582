export default {
    game_preorder_public: {
        "default": {
            "dsn": "header=game_header;common_spacer;main=common_title,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_preorder_public_title"
                }
            }
        },
    },
}