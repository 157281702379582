import React, {useEffect} from "react";
import Spinner from "@gotombola/react-ds/lib/atoms/Spinner";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function ExternalPaymentBuyBookGameForm({ game, book, locale }: ExternalPaymentBuyBookGameFormProps) {
    const {t} = useTypeTranslation('game', game);
    const query = useQueryString();

    useEffect(() => {
        const n = setTimeout(() => {
            query.set('k', book.code);
            locale && !query.has('locale') && query.set('locale', locale);
            location.href = `${process.env.RAZZLE_GOTOMBOLA_API_PAYMENT_ENDPOINT}/payments/new/game-bookpayment?${query.toString()}`;
        }, 1000);
        return () => {
            clearTimeout(n);
        }
    }, [query, book.code, locale])

    return (
        <Spinner />
    );
}

export interface ExternalPaymentBuyBookGameFormProps {
    game: any;
    book: any;
    workflows: any;
    locale: string;
}

export default ExternalPaymentBuyBookGameForm;
