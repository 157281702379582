export default {
    game_public: {
        modes: {
            RUNNING: ({game}: any) => game?.features?.includes('push') && ({
                appId: game.pushNotificationAppId || process.env.RAZZLE_GOTOMBOLA_PUSH_NOTIFICATION_APP_ID || '',
                welcomeNotification: {
                    title: "Ne manquez pas le tirage au sort !",
                    message: "Cliquez-ici pour être notifié en temps réel des résultats du tirage au sort lorsque ceux-ci seront disponible !",
                    url: game.publicPageShortUrl,
                }
            }),
            PAUSED: ({game}: any) => game?.features?.includes('push') && ({
                appId: game.pushNotificationAppId || process.env.RAZZLE_GOTOMBOLA_PUSH_NOTIFICATION_APP_ID || '',
                welcomeNotification: {
                    title: "Ne manquez pas le tirage au sort !",
                    message: "Cliquez-ici pour être notifié en temps réel des résultats du tirage au sort lorsque ceux-ci seront disponible !",
                    url: game.publicPageShortUrl,
                }
            }),
            CLOSED: ({game}: any) => game?.features?.includes('push') && ({
                appId: game.pushNotificationAppId || process.env.RAZZLE_GOTOMBOLA_PUSH_NOTIFICATION_APP_ID || '',
                welcomeNotification: {
                    title: "Ne manquez pas le tirage au sort !",
                    message: "Cliquez-ici pour être notifié en temps réel des résultats du tirage au sort lorsque ceux-ci seront disponible !",
                    url: game.publicPageShortUrl,
                }
            }),
            DRAWN: ({game}: any) => game?.features?.includes('push') && ('PUBLISHED' !== game?.resultsPublishingStatus) && ({
                appId: game.pushNotificationAppId || process.env.RAZZLE_GOTOMBOLA_PUSH_NOTIFICATION_APP_ID || '',
                welcomeNotification: {
                    title: "Ne manquez pas le tirage au sort !",
                    message: "Cliquez-ici pour être notifié en temps réel des résultats du tirage au sort lorsque ceux-ci seront disponible !",
                    url: game.publicPageShortUrl,
                }
            }),
        }
    },
    game_communication: {
        modes: {
            RUNNING: ({game}: any) => game?.features?.includes('push') && ({
                appId: game.pushNotificationAppId || process.env.RAZZLE_GOTOMBOLA_PUSH_NOTIFICATION_APP_ID || '',
                welcomeNotification: {
                    title: "Ne manquez pas le tirage au sort !",
                    message: "Cliquez-ici pour être notifié en temps réel des résultats du tirage au sort lorsque ceux-ci seront disponible !",
                    url: game.publicPageShortUrl,
                }
            }),
        }
    },
    game_terms_and_conditions: {
        modes: {
            RUNNING: ({game}: any) => game?.features?.includes('push') && ({
                appId: game.pushNotificationAppId || process.env.RAZZLE_GOTOMBOLA_PUSH_NOTIFICATION_APP_ID || '',
                welcomeNotification: {
                    title: "Ne manquez pas le tirage au sort !",
                    message: "Cliquez-ici pour être notifié en temps réel des résultats du tirage au sort lorsque ceux-ci seront disponible !",
                    url: game.publicPageShortUrl,
                }
            }),
        }
    },
}