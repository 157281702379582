import modules from './modules';
import pixel from './pixel';
import publicPages from './public-pages';
import routes from './routes';
import templateDefinitions from './template-definitions';

export default {
    modules,
    pixel,
    publicPages,
    routes,
    templateDefinitions,
}