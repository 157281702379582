export default {
    game_sellergroup_registration__default: {
        steps: {
            start: {url: 'game_sellergroup_registration_welcome', redirect: 'team', ssr: true},
            team: {url: 'game_sellergroup_registration_team', ssr: true, next: 'owner', nextFn: ({extraVars}: {doc: any, extraVars: any}) => {
                    return ({doc: extraVars?.data, extraVars: {}});
            }},
            owner: {url: 'game_sellergroup_registration_owner', next: 'game_sellergroup_registration_complete:summary', nextFn: ({extraVars}: {doc: any, extraVars: any}) => {
                    return ({doc: extraVars?.data, extraVars: {}});
                }},
        },
    },
    game_sellergroup_registration_complete__default: {
        steps: {
            summary: {url: 'game_sellergroup_registration_summary', ssr: true},
        },
    },
};
