import * as pages from './pages';

const routeMap = {
    tenant_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'tenant_', ssr: 'tenant_public', component: pages.tenant_public},
];

export default {configure: () => ({routeMap, routeList, pages})};