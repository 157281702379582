import pixel from './pixel';
import routes from './routes';
import workflows from './workflows';
import modules from './modules';
import publicPages from './public-pages';
import push from './push';
import templateDefinitions from './template-definitions';
import actions from './actions';

export default {
    modules,
    pixel,
    publicPages,
    push,
    routes,
    templateDefinitions,
    actions,
    workflows,
}