import React from "react";
import {Game, Presult} from "@gotombola/sdk";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function PresultLoosingTickets({presult, game}: PresultLoosingTicketsProps) {
    const {t} = useTypeTranslation('game', game);

    return (
        <>
            {presult.winningTicketCount > 0 && presult.loosingTicketCount > 0 && <div className={'font-go-text text-go-text text-2xl'}>{t('presult_looser_public_page_section', {quantity: presult.loosingTicketCount})}</div>}
            {presult.winningTicketCount > 0 && presult.loosingTicketCount >= 1 && presult.loosingTicketCodes?.map((code: string, index: number) => {
                return (
                    <div key={index} className={'font-go-text text-go-text'}>{code}</div>
                )
            })}
        </>
    );
}

export interface PresultLoosingTicketsProps {
    presult: Presult;
    game: Game;
}

export default PresultLoosingTickets;