import React from "react";
import Link from "@gotombola/react-ds/lib/atoms/Link";
import usePageTypeTranslation from "@gotombola/react-ds/lib/hooks/usePageTypeTranslation";

export function DrawLiveGameForm({ game, draw }: DrawLiveGameFormProps) {
    const {t} = usePageTypeTranslation('draw_live', 'game', game);
    const timestamp = String(new Date().valueOf());
    return (
        <div className={'flex flex-col items-center w-full px-4 md:px-16 sm:pb-4 mb-24 space-y-4 sm:space-y-10 mx-auto'}>
            {draw?.winnersCount > 1 && <div className={'font-go-text text-go-text font-light text-xl text-center'}>{t('text')}</div>}
            <div className={'font-go-text text-go-text font-light text-xl text-center'}>{t('subtitle', { count: draw?.winnersCount })}</div>
            <Link className={'text-center'} size={'lg'} label={t('next_button_label')} href={1 === draw?.winnersCount ? `./live/run?_=${timestamp}` : './live/direction'} />
        </div>
    );
}

export interface DrawLiveGameFormProps {
    game: any;
    draw: any;
}

export default DrawLiveGameForm;
