import React, {useMemo} from 'react';
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import DisplayDrawResultsGameForm from "../molecules/DisplayDrawResultsGameForm";

export const GameDrawResultsPage = editablePage('game', [], ({staticContext: {game, draw, locale, mode}}: any) => {
    const {pixel, push, publicPages, templates} = useMode(mode);

    draw = useMemo(() => {
        return game.winners ? ({
            winnersCount: game.winners?.items?.length || 0,
            winners: game.winners || {items: []},
        }) : draw; // keep returning `draw` as a fallback when games that have not game.winners populated yet
    }, [game, draw]);

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);

    return (
        <GamePageWrapper game={game} locale={locale}>
            <TypePage type={'game'} doc={game} page={'draw_results'} locale={locale}
                      publicPages={publicPages} templates={templates}
                      pixels={pixel} pushs={push}
                      pixelProps={pixelProps} pushProps={pushProps}
            >
                <DisplayDrawResultsGameForm game={game} draw={draw} />
                <Pixel app={pixel.app} event={pixel.pages.game_draw_results} />
            </TypePage>
        </GamePageWrapper>
    );
});

export default GameDrawResultsPage;
