import * as pages from './pages';

const routeMap = {
    godfather_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'godfather_', ssr: 'godfather_public', component: pages.godfather_public},
];

export default {configure: () => ({routeMap, routeList, pages})};