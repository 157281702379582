import React from 'react';
import {useMode} from "../../../utils/common";
import SimplePage from "@gotombola/react-ds/lib/pages/SimplePage";

export default function HomePage({staticContext: {mode}}: any) {
    const {pixel} = useMode(mode);

    return (
        <SimplePage page={'home'} pixels={pixel} />
    );
}