import React, {useMemo} from 'react'
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import PaymentBuyBookGameForm from "../molecules/PaymentBuyBookGameForm";

export const GameBuyBookPaymentPage = editablePage('game', [], ({staticContext: {game, book, locale, mode}}: any) => {
    const {pixel, push, publicPages, templates, workflows} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code, kc: book.code}), [game.code, book.code]);
    const pushProps = useMemo(() => ({mode: game.status, game, book}), [game, game.status, book]);

    return (
        <GamePageWrapper game={game} locale={locale}>
            <TypePage type={'game'} doc={game} page={'buy_book_payment'} locale={locale}
                      publicPages={publicPages} templates={templates}
                      pixels={pixel} pushs={push}
                      pixelProps={pixelProps} pushProps={pushProps}
                      noindex
            >
                <PaymentBuyBookGameForm game={game} book={book} workflows={workflows} />
                <Pixel app={pixel.app} event={pixel.pages.game_buy_book_payment} gc={game.code} kc={book.code} />
            </TypePage>
        </GamePageWrapper>
    );
});

export default GameBuyBookPaymentPage;
