import clsx from "clsx";
import Spinner2 from "@gotombola/react-ds/lib/atoms/Spinner2";
import React, {useCallback, useMemo, useState} from "react";
import {Game, Sale} from "@gotombola/sdk";
import useTypeUrl from "@gotombola/react-ds/lib/hooks/useTypeUrl";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function SaleButtons({game, sale, contactus = false}: SaleButtonsProps) {
    const {t} = useTypeTranslation('game', game);
    const shareUrl = useTypeUrl('game', game, 'share', `z=${sale.ownerGodfatherCode}`);
    const publicUrl = useTypeUrl('game', game, '', `z=${sale.ownerGodfatherCode}`);
    const [ticketsPdfIsClicked, setTicketsPdfIsClicked] = useState(false);
    const handleTicketsPdfClick = useCallback(() => {
        setTicketsPdfIsClicked(true);
    }, [setTicketsPdfIsClicked]);
    const isShareEnabled = useMemo(() => !game?.features?.includes('hide_godfather_share'), [game?.features]);

    return (
        <div className={'flex flex-col w-full justify-items-end sm:justify-end space-y-2 md:pl-8 pt-2 sm:pt-0'}>
            {!!sale?.publicTicketsPdfUrl && !!sale?.ownedTickets?.items?.length && <a href={sale?.publicTicketsPdfUrl} onClick={handleTicketsPdfClick} aria-disabled={ticketsPdfIsClicked}
                                                                                      className={clsx("flex items-center justify-center text-center bg-go-primary text-white cursor-pointer font-bold text-2xl hover:bg-go-secondary rounded-md mb-2 min-h-[32px] p-4 sm:p-2 lg:p-4")}>
                {ticketsPdfIsClicked && <Spinner2 color={'clear'} />}
                {!ticketsPdfIsClicked && t('sale_public_page_download_btn_label')}
            </a>}
            {!!contactus && <a href={`mailto:contact@gotombola.co?subject=GoTombola&body=(ID:${sale.id})`} className={clsx("flex items-center justify-center text-center bg-go-primary text-white cursor-pointer font-bold text-2xl hover:bg-go-secondary rounded-md mb-2 min-h-[32px] p-4 sm:p-2 lg:p-4")}>
                {t('sale_public_page_contactus_btn_label')}
            </a>}
            {isShareEnabled && <div className={'absolute sm:relative flex flex-col'}>
                <a href={shareUrl}
                   className={"z-20 sm:z-0 text-center bg-go-primary sm:bg-go-secondary fixed sm:relative bottom-0 left-0 right-0 text-white cursor-pointer font-bold text-2xl sm:text-xl hover:bg-go-secondary sm:hover:bg-go-primary p-4 sm:p-2 sm:rounded-md"}>
                    {t('sale_public_page_share_btn_label')}
                </a>
            </div>}
            <a href={publicUrl}
               className={"text-center bg-go-secondary text-white cursor-pointer font-bold text-xl hover:bg-go-primary p-2 rounded-md"}>
                {t('sale_public_page_go_game_btn_label')}
            </a>
        </div>
    );
}

export interface SaleButtonsProps {
    game: Game & Record<string, any>;
    sale: Sale & Record<string, any>;
    contactus?: boolean;
}

export default SaleButtons;
