import * as pages from './pages';

const routeMap = {
    ticket_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'ticket_', ssr: 'ticket_public', component: pages.ticket_public},
];

export default {configure: () => ({routeMap, routeList, pages})};