export default {
    seller_public: {
        modes: {
            RUNNING: ({game}: any) => game?.features?.includes('push') && ({
                appId: game.pushNotificationAppId || process.env.RAZZLE_GOTOMBOLA_PUSH_NOTIFICATION_APP_ID || '',
                welcomeNotification: {
                    title: "Ne manquez pas le tirage au sort !",
                    message: "Cliquez-ici pour être notifié en temps réel des résultats du tirage au sort lorsque ceux-ci seront disponible !",
                    url: game.publicPageShortUrl,
                }
            }),
        }
    }
}