import React, {useCallback, useMemo} from 'react'
import {useMode} from "../../../utils/common";
import TypePage from '@gotombola/react-ds/lib/pages/TypePage';

export default function GameSellergroupRegistrationTeamPage({staticContext: {game, registration, locale, mode}}: any) {
    const {pixel, push, publicPages, templates, workflows, actions} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);

    const extraFn = useCallback(() => ({registration}), [registration]);

    return (
        <TypePage type={'game'} doc={game} page={'sellergroup_registration_team'} locale={locale}
                  publicPages={publicPages} templates={templates}
                  pixels={pixel} pushs={push}
                  pixelProps={pixelProps} pushProps={pushProps}
                  workflows={workflows} actions={actions}
                  extraFn={extraFn}
        />
    );
}