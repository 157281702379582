import React from "react";
import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import useGameBuyBookBonuses from "../../../hooks/useGameBuyBookBonuses";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function BonusBuyBookGameForm({ game, book, workflows }: BonusBuyBookGameFormProps) {
    const {t} = useTypeTranslation('game', game);

    const items = useGameBuyBookBonuses(book, game, { t, workflows });

    return (
        <ChoiceMenu items={items} />
    );
}

export interface BonusBuyBookGameFormProps {
    game: any;
    book: any;
    workflows: any;
}

export default BonusBuyBookGameForm;
