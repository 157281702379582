export default {
    organization_public: {
        modes: {
            CREATED: ({organization}: any) => organization?.features?.includes('push') && ({
                appId: organization.pushNotificationAppId || process.env.RAZZLE_GOTOMBOLA_PUSH_NOTIFICATION_APP_ID || '',
                welcomeNotification: {
                    title: "Ne manquez pas nos futures collectes de fonds !",
                    message: "Cliquez-ici pour être notifié en temps réel des prochaines collectes de fonds !",
                    url: organization.publicPageShortUrl,
                }
            }),
        }
    },
}