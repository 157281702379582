import * as pages from './pages';

const routeMap = {
    seller_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'seller_', ssr: 'seller_public', component: pages.seller_public},
];

export default {configure: () => ({routeMap, routeList, pages})};