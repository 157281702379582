import React, {useMemo} from 'react'
import {useMode} from "../../../utils/common";
import TypePage from '@gotombola/react-ds/lib/pages/TypePage';

export default function OrganizationPublicPage({staticContext: {organization, locale, mode}}: any) {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({oc: organization.code}), [organization.code]);
    const pushProps = useMemo(() => ({mode: organization.status, organization}), [organization, organization.status]);

    return (
        <TypePage type={'organization'} doc={organization} page={'public'} locale={locale}
                  publicPages={publicPages} templates={templates}
                  pixels={pixel} pushs={push}
                  pixelProps={pixelProps} pushProps={pushProps}
        />
    );
}