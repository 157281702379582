export default {
    game_public_page: { common: { template: 'dynamic_game_public' } },
    game_draw_results_page: { common: { template: 'dynamic_game_public' } },
    game_draw_live_page: { common: { template: 'dynamic_game_public' } },
    game_draw_live_run_page: { common: { template: 'dynamic_game_public' } },
    game_draw_live_direction_page: { common: { template: 'dynamic_game_public' } },
    game_share_page: { common: { template: 'dynamic_game_public' } },
    game_terms_and_conditions_page: { common: { template: 'dynamic_game_public' } },
    game_legal_page: { common: { template: 'dynamic_game_public' } },
    game_inbox_page: { common: { template: 'dynamic_game_public' } },
    game_communication_page: { common: { template: 'dynamic_game_public' } },
    game_sellergroup_registration_welcome_page: { common: { template: 'dynamic_game_public' } },
    game_sellergroup_registration_team_page: { common: { template: 'dynamic_game_public' } },
    game_sellergroup_registration_owner_page: { common: { template: 'dynamic_game_public' } },
    game_sellergroup_registration_summary_page: { common: { template: 'dynamic_game_public' } },
    game_leaderboard_page: { common: { template: 'dynamic_game_public' } },
    game_files_page: { common: { template: 'dynamic_game_public' } },
    game_account_link_request_page: { common: { template: 'dynamic_game_public' } },
    game_godfathers_ranking_page: { common: { template: 'dynamic_game_public' } },
};
