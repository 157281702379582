import * as pages from './pages';

const routeMap = {
    game_leaderboard: {
        de_DE: '/rangfolge',
        en_GB: '/ranking',
        en_US: '/ranking',
        es_ES: '/clasificacion',
        fr_BE: '/classement',
        fr_CA: '/classement',
        fr_CH: '/classement',
        fr_FR: '/classement',
        fr_LU: '/classement',
        fr_MC: '/classement',
        it_IT: '/classifica',
        nl_BE: '/rangschikking',
        pt_BR: '/classificacao',
        pt_PT: '/classificacao',
    },
    game_godfathers_ranking: {
        de_DE: '/ranglisten/sponsoren/:period?',
        en_GB: '/rankings/godfathers/:period?',
        en_US: '/rankings/godfathers/:period?',
        es_ES: '/clasificaciones/patrocinadores/:period?',
        fr_BE: '/classements/parrains/:period?',
        fr_CA: '/classements/parrains/:period?',
        fr_CH: '/classements/parrains/:period?',
        fr_FR: '/classements/parrains/:period?',
        fr_LU: '/classements/parrains/:period?',
        fr_MC: '/classements/parrains/:period?',
        it_IT: '/classifiche/sponsor/:period?',
        nl_BE: '/klassement/sponsors/:period?',
        pt_BR: '/classificacoes/patrocinadores/:period?',
        pt_PT: '/classificacoes/patrocinadores/:period?',
    },
    game_draw_live: {
        de_DE: '/auslosung/:publicToken/live',
        en_GB: '/draw/:publicToken/live',
        en_US: '/draw/:publicToken/live',
        es_ES: '/dibujar/:publicToken/en-directo',
        fr_BE: '/tirage/:publicToken/temps-reel',
        fr_CA: '/tirage/:publicToken/temps-reel',
        fr_CH: '/tirage/:publicToken/temps-reel',
        fr_FR: '/tirage/:publicToken/temps-reel',
        fr_LU: '/tirage/:publicToken/temps-reel',
        fr_MC: '/tirage/:publicToken/temps-reel',
        it_IT: '/sorteggio/:publicToken/dal-vivo',
        nl_BE: '/loting/:publicToken/live',
        pt_BR: '/sorteio/:publicToken/ao-vivo',
        pt_PT: '/sorteio/:publicToken/ao-vivo',
    },
    game_draw_live_direction: {
        de_DE: '/auslosung/:publicToken/live/sinn',
        en_GB: '/draw/:publicToken/live/direction',
        en_US: '/draw/:publicToken/live/direction',
        es_ES: '/dibujar/:publicToken/en-directo/direccion',
        fr_BE: '/tirage/:publicToken/temps-reel/sens',
        fr_CA: '/tirage/:publicToken/temps-reel/sens',
        fr_CH: '/tirage/:publicToken/temps-reel/sens',
        fr_FR: '/tirage/:publicToken/temps-reel/sens',
        fr_LU: '/tirage/:publicToken/temps-reel/sens',
        fr_MC: '/tirage/:publicToken/temps-reel/sens',
        it_IT: '/sorteggio/:publicToken/dal-vivo/direzione',
        nl_BE: '/loting/:publicToken/live/richting',
        pt_BR: '/sorteio/:publicToken/ao-vivo/direcao',
        pt_PT: '/sorteio/:publicToken/ao-vivo/direccao',
    },
    game_draw_live_run: {
        de_DE: '/auslosung/:publicToken/live/laufen',
        en_GB: '/draw/:publicToken/live/run',
        en_US: '/draw/:publicToken/live/run',
        es_ES: '/dibujar/:publicToken/en-directo/ejecutar',
        fr_BE: '/tirage/:publicToken/temps-reel/lancer',
        fr_CA: '/tirage/:publicToken/temps-reel/lancer',
        fr_CH: '/tirage/:publicToken/temps-reel/lancer',
        fr_FR: '/tirage/:publicToken/temps-reel/lancer',
        fr_LU: '/tirage/:publicToken/temps-reel/lancer',
        fr_MC: '/tirage/:publicToken/temps-reel/lancer',
        it_IT: '/sorteggio/:publicToken/dal-vivo/lancio',
        nl_BE: '/loting/:publicToken/live/lanceer',
        pt_BR: '/sorteio/:publicToken/ao-vivo/lancar',
        pt_PT: '/sorteio/:publicToken/ao-vivo/lancar',
    },
    game_results: {
        de_DE: '/resultate',
        en_GB: '/results',
        en_US: '/results',
        es_ES: '/resultados',
        fr_BE: '/resultats',
        fr_CA: '/resultats',
        fr_CH: '/resultats',
        fr_FR: '/resultats',
        fr_LU: '/resultats',
        fr_MC: '/resultats',
        it_IT: '/risultati',
        nl_BE: '/resultaten',
        pt_BR: '/resultados',
        pt_PT: '/resultados',
    },
    game_share: {
        de_DE: '/aktie',
        en_GB: '/share',
        en_US: '/share',
        es_ES: '/compartir',
        fr_BE: '/partager',
        fr_CA: '/partager',
        fr_CH: '/partager',
        fr_FR: '/partager',
        fr_LU: '/partager',
        fr_MC: '/partager',
        it_IT: '/quota',
        nl_BE: '/delen',
        pt_BR: '/compartilhar',
        pt_PT: '/partilhar',
    },
    game_terms_and_conditions: {
        de_DE: '/bedingungen-und-konditionen',
        en_GB: '/terms-and-conditions',
        en_US: '/terms-and-conditions',
        es_ES: '/condiciones-generales',
        fr_BE: '/reglement',
        fr_CA: '/reglement',
        fr_CH: '/reglement',
        fr_FR: '/reglement',
        fr_LU: '/reglement',
        fr_MC: '/reglement',
        it_IT: '/regolamento',
        nl_BE: '/loten',
        pt_BR: '/bilhetes',
        pt_PT: '/bilhetes',
    },
    game_legal: {
        de_DE: '/legal',
        en_GB: '/legal',
        en_US: '/legal',
        es_ES: '/avisos-legales',
        fr_BE: '/mentions-legales',
        fr_CA: '/mentions-legales',
        fr_CH: '/mentions-legales',
        fr_FR: '/mentions-legales',
        fr_LU: '/mentions-legales',
        fr_MC: '/mentions-legales',
        it_IT: '/avvisi-legali',
        nl_BE: '/juridische-kennisgevingen',
        pt_BR: '/avisos-juridicos',
        pt_PT: '/avisos-legais',
    },
    game_inbox: {
        de_DE: '/posteingang',
        en_GB: '/inbox',
        en_US: '/inbox',
        es_ES: '/bandeja-de-entrada',
        fr_BE: '/boite-de-reception',
        fr_CA: '/boite-de-reception',
        fr_CH: '/boite-de-reception',
        fr_FR: '/boite-de-reception',
        fr_LU: '/boite-de-reception',
        fr_MC: '/boite-de-reception',
        it_IT: '/in-arrivo',
        nl_BE: '/inbox',
        pt_BR: '/caixa-de-entrada',
        pt_PT: '/caixa-de-entrada',
    },
    game_communication: {
        de_DE: '/kommunikation',
        en_GB: '/communication',
        en_US: '/communication',
        es_ES: '/comunicacion',
        fr_BE: '/communication',
        fr_CA: '/communication',
        fr_CH: '/communication',
        fr_FR: '/communication',
        fr_LU: '/communication',
        fr_MC: '/communication',
        it_IT: '/comunicazione',
        nl_BE: '/communicatie',
        pt_BR: '/comunicacao',
        pt_PT: '/comunicacao',
    },
    game_files: {
        de_DE: '/dateien',
        en_GB: '/files',
        en_US: '/files',
        es_ES: '/archivos',
        fr_BE: '/fichiers',
        fr_CA: '/fichiers',
        fr_CH: '/fichiers',
        fr_FR: '/fichiers',
        fr_LU: '/fichiers',
        fr_MC: '/fichiers',
        it_IT: '/file',
        nl_BE: '/bestanden',
        pt_BR: '/arquivos',
        pt_PT: '/ficheiros',
    },
    game_sellergroup_registration_welcome: {
        en_US: '/registrations/welcome',
        fr_FR: '/inscriptions/bienvenu',
    },
    game_sellergroup_registration_team: {
        en_US: '/registrations/team',
        fr_FR: '/inscriptions/equipe',
    },
    game_sellergroup_registration_owner: {
        en_US: '/registrations/owner',
        fr_FR: '/inscriptions/identite',
    },
    game_sellergroup_registration_summary: {
        en_US: '/registrations/summary',
        fr_FR: '/inscriptions/resume',
    },
    game_account_link_request: {
        en_US: '/my-account',
        fr_FR: '/mon-compte',
    },
    game_: {
        en_US: '/',
    }
}

const routeList = [
    'game_draw_live',
    'game_draw_live_direction',
    'game_draw_live_run',
    'game_results',
    'game_share',
    'game_terms_and_conditions',
    'game_legal',
    'game_inbox',
    'game_communication',
    'game_sellergroup_registration_welcome',
    'game_sellergroup_registration_team',
    'game_sellergroup_registration_owner',
    'game_sellergroup_registration_summary',
    'game_leaderboard',
    'game_files',
    'game_account_link_request',
    'game_godfathers_ranking',
    {name: 'game_', ssr: 'game_public', component: pages.game_public},
];

export default {configure: () => ({routeMap, routeList, pages})};
