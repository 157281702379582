import React from "react";
import Link from "@gotombola/react-ds/lib/atoms/Link";
import usePageTypeTranslation from "@gotombola/react-ds/lib/hooks/usePageTypeTranslation";

export function DirectionDrawLiveGameForm({ game }: DirectionDrawLiveGameFormProps) {
    const {t} = usePageTypeTranslation('draw_live_direction', 'game', game);
    const timestamp = String(new Date().valueOf());
    return (
        <div className={'flex flex-col items-center w-full px-4 md:px-16 sm:pb-4 mb-24 space-y-4 sm:space-y-10 mx-auto'}>
            <div className={'font-go-text text-go-text font-light text-xl text-center'}>{t('text')}</div>
            <div className={'font-go-text text-go-text font-medium text-xl text-center'}>{t('subtitle')}</div>
            <div className={'flex gap-4'}>
                <Link label={t('next_asc_button_label')} href={`./run?_=${timestamp}`}/>
                <Link color={'secondary'} label={t('next_desc_button_label')}
                      href={`./run?direction=desc&_=${timestamp}`}/>
            </div>
        </div>
    );
}

export interface DirectionDrawLiveGameFormProps {
    game: any;
    draw: any;
}

export default DirectionDrawLiveGameForm;
