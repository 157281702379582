import React, {Fragment, useCallback, useState} from "react";
import base64encodeValue from "../../../utils/base64encodeValue";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";
import DynamicCustomChildren from "@gotombola/react-ds/lib/molecules/DynamicCustomChildren";
import clsx from "clsx";

const colors = 'border-go-choice-secondary bg-go-choice-secondary text-go-choice-primary hover:bg-go-choice-primary hover:text-go-choice-secondary';

export function CustomBuyGameForm({ game, workflows }: CustomBuyGameFormProps) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const {t} = useTypeTranslation('game', game);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'custom', {game});
    const inputs = game?.inputs?.items?.map(({type, code, name, params, placeholder, defaultValue, required, rank, description}: any) => {
        return {type, name: code, label: name, placeholder: placeholder || name, defaultValue, required, params, rank, description};
    }) || [];
    const defaultValues = {};
    const requiredValues = {};
    game.inputs.items && game.inputs.items.forEach((input: any) => {
        defaultValues[input.code] = input.defaultValue;
        ((input.required === undefined) || (input.required === true)) && (requiredValues[input.code] = true);
    });
    const [data, setData] = useState(defaultValues);
    const handleChange = useCallback((event: any) => {
        !!event.target && setData({...data, [event.target.name]: event.target.value});
        !event.target && setData({...data, [event.name]: event.value});
    }, [data, setData]);
    const handleSubmit = useCallback(() => {
        query.set('da', base64encodeValue(data));
        const url = buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder);
        url && (location.href = url);
    }, [query, data, game, nextStep, urlBuilder]);
    const isButtonDisabled = !!Object.entries(requiredValues).find(x => (data[x[0]] === null) || data[x[0]] === undefined || data[x[0]] === '' || data[x[0]] === false);

    inputs.sort((a: any, b: any) => a?.rank < b?.rank ? -1 : (a?.rank > b?.rank ? 1 : 0));

    const message = game?.['buyCustomMessage'] || game?.['customMessage'] || t('game_buy_custom_page_message');

    return (
        <div className={'w-full space-y-4'}>
            {!!message && <div className={'text-justify w-full'}>{message}</div>}
            <form className={"flex flex-col mt-8 space-y-4 w-full"}>
                {(inputs || []).map((item: any, index: number) => {
                    let extra: Record<string, any> = {};
                    try {
                        item.params && (extra = JSON.parse(item.params));
                    } catch (e: any) {
                        // unable to parse params
                    }
                    return (<Fragment key={index}>
                        <DynamicCustomChildren onChange={handleChange} placeholder={item.name}
                                               nolabel {...item} {...extra} {...(index === 0 ? {autoFocus: true} : {})} />
                        {!!item.description && <div
                            className={clsx('font-go-text text-go-text w-full font-light text-left', index < (inputs.length - 1) ? 'pb-3' : '')}>{item.description}</div>}
                    </Fragment>)
                })}
                <div className={'w-full flex pt-4'}>
                    <button
                        type={"button"}
                        className={clsx("font-go-button w-full p-3 border disabled:border-gray-300 disabled:text-white disabled:bg-gray-300 rounded-md text-xl", !isButtonDisabled && colors)}
                        disabled={isButtonDisabled}
                        onClick={handleSubmit}
                    >
                        {t('game_buy_custom_page_submit_label')}
                    </button>
                </div>
            </form>
        </div>
    );
}

export interface CustomBuyGameFormProps {
    game: any;
    workflows: any;
}

export default CustomBuyGameForm;
