import * as pages from './pages';

const routeMap = {
    sponsoring_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'sponsoring_', ssr: 'sponsoring_public', component: pages.sponsoring_public},
];

export default {configure: () => ({routeMap, routeList, pages})};