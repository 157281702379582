import React from 'react';
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";
import {Game, Sale} from "@gotombola/sdk";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import clsx from "clsx";

const statusClasses: any = {
    CREATED: {
        tag: 'bg-gray-300 text-black',
    },
    PREORDERED: {
        tag: 'bg-blue-400 text-white',
    },
    PROMISED: {
        tag: 'bg-purple-400 text-white',
    },
    PAID: {
        tag: 'bg-green-400 text-white',
    },
    CANCELED: {
        tag: 'bg-red-400 text-white',
    },
    FAILED: {
        tag: 'bg-red-400 text-white',
    },
}
export function SaleDetails({game, sale}: SaleDetailsProps) {
    const {t} = useTypeTranslation('game', game);
    const date = new Date(parseInt(sale.createdAt));

    return (
        <div className={"flex flex-col space-y-4"}>
            <div className={"border border-gray-200 bg-white rounded-md"}>
                <div
                    className={"font-go-text flex flex-row justify-between items-center p-6 text-2xl font-bold text-gray-600 bg-gray-50 border-b"}>
                    <div className={'font-go-text text-go-text'}>{t([`sale_public_page_details_${(sale.status || '').toLowerCase()}`, 'sale_public_page_details'])}</div>
                </div>
                <div className={"space-y-4 text-xl"}>
                    <div className={"border-b border-gray-200 px-6 py-4 flex justify-between items-center"}>
                        <div className={"font-go-text text-gray-500"}>{t('sale_public_page_status')}</div>
                        <div className={"text-gray-800 text-right"}>
                            <span className={clsx('font-go-text text-go-text p-2 rounded', statusClasses[sale.status]?.tag)}>{t([`sale_status_${(sale.status || '').toLowerCase()}`, sale.status])}</span>
                        </div>
                    </div>
                    <div className={"border-b border-gray-200 px-6 pb-4 flex justify-between items-center"}>
                        <div className={"font-go-text text-gray-500"}>{t('sale_public_page_ref')}</div>
                        <div className={"font-go-text text-gray-800 font-bold text-right"}>{sale.id}</div>
                    </div>
                    <div className={"border-b border-gray-200 px-6 pb-4 flex justify-between items-center"}>
                        <div className={"font-go-text text-gray-500"}>{t('sale_public_page_amount')}</div>
                        <div className={"font-go-text text-gray-800 text-right"}>{formatRawAmount(sale.paymentAmount, sale.paymentCurrency)}</div>
                    </div>
                    <div className={"border-b border-gray-200 px-6 pb-4 flex justify-between items-center"}>
                        <div className={"font-go-text text-gray-500"}>{t('sale_public_page_date')}</div>
                        <div className={"font-go-text text-gray-800 text-right"}>{date.toLocaleDateString("fr")}</div>
                    </div>
                    {!!sale.tickets && <div className={`${sale.donation ? "border-b border-gray-200" : ''} px-6 pb-4 flex justify-between items-center`}>
                        <div className={"font-go-text text-gray-500"}>{t('sale_public_page_number')}</div>
                        <div className={"font-go-text text-gray-800 text-right"}>{sale.tickets}</div>
                    </div>}
                    {sale.donation ?
                        <div className={"px-6 pb-4 flex justify-between items-center"}>
                            <div className={"font-go-text text-gray-500"}>{t('sale_public_page_donation_amount')}</div>
                            <div className={"font-go-text text-gray-800 text-right"}>{formatRawAmount(sale.donation, sale.paymentCurrency)}</div>
                        </div> : ""}
                </div>
            </div>
        </div>
    );
}

export interface SaleDetailsProps {
    game: Game & Record<string, any>;
    sale: Sale & Record<string, any>;
}

export default SaleDetails;