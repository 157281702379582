import loadable from '@loadable/component';

export const game_buy_quantity = loadable(() => import("./GameBuyQuantityPage"));
export const game_buy_payment = loadable(() => import("./GameBuyPaymentPage"));
export const game_buy_bonus = loadable(() => import("./GameBuyBonusPage"));
export const game_buy_owner = loadable(() => import("./GameBuyOwnerPage"));
export const game_buy_request = loadable(() => import("./GameBuyRequestPage"));
export const game_buy_custom = loadable(() => import("./GameBuyCustomPage"));
export const game_buy_beneficiary = loadable(() => import("./GameBuyBeneficiaryPage"));
export const game_buy_project = loadable(() => import("./GameBuyProjectPage"));
export const game_buy_external_payment = loadable(() => import("./GameBuyExternalPaymentPage"));
export const game_buy_seller_group_groupings = loadable(() => import("./GameBuySellerGroupGroupingsPage"));
export const game_buy_seller_group = loadable(() => import("./GameBuySellerGroupPage"));
export const game_buy_seller = loadable(() => import("./GameBuySellerPage"));
export const game_buy_privacy = loadable(() => import("./GameBuyPrivacyPage"));
export const game_buy_donation = loadable(() => import("./GameBuyDonationPage"));
export const game_buy_custom_quantity = loadable(() => import("./GameBuyCustomQuantityPage"));