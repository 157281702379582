export default {
    partner_public: {
        "default": "type_effects;header=partner_header;main=common_spacer,partner_service_push,partner_service_quote,common_spacer,partner_service_features,partner_service_pricing,common_dynbody,type_demo_banner,partner_service_sale_process_screenshots,partner_service_public_customization_screenshots,partner_service_automatic_emails_screenshots,partner_service_calltoaction,common_spacer,partner_mobile_calltoaction;footer=partner_footer,common_tenant_footer",
        "platform": "type_effects;header=partner_header;main=common_spacer,partner_service_push,partner_service_quote,common_spacer,partner_service_features,partner_service_pricing,common_dynbody,type_demo_banner,partner_service_sale_process_screenshots,partner_service_public_customization_screenshots,partner_service_automatic_emails_screenshots,partner_service_calltoaction,common_spacer,partner_mobile_calltoaction;footer=partner_footer,common_tenant_footer",
        "printer": {
            "dsn": "type_effects;header=partner_header;main=partner_banner,common_spacer,partner_service_features,partner_service_pricing,common_dynbody,type_demo_banner,partner_service_sale_process_screenshots,partner_service_public_customization_screenshots,partner_service_automatic_emails_screenshots,partner_service_calltoaction,common_spacer,partner_mobile_calltoaction;footer=partner_footer,common_tenant_footer",
            "params": {
                "partner_banner": {
                    "type": "cta"
                }
            }
        }
    },
    partner_register: {
        "default": {
            "dsn": "type_effects;header=partner_header;main=common_dynbody,type_demo_banner,partner_register_form;footer=partner_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "partner_header": {
                    "light": true
                }
            }
        }
    },
    partner_register_website: {
        "default": {
            "dsn": "type_effects;header=partner_header;main=common_dynbody,type_demo_banner,partner_register_form;footer=partner_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "partner_header": {
                    "light": true
                },
                "partner_register_form": {
                    "type": "website"
                }
            }
        }
    },
    partner_registration: {
        "default": {
            "dsn": "type_effects;header=partner_header;main=common_dynbody,type_demo_banner,partner_registration_form;footer=partner_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "partner_header": {
                    "light": true
                }
            }
        }
    },
    partner_registration_game: {
        "default": {
            "dsn": "type_effects;header=partner_header;main=common_dynbody,type_demo_banner,partner_registration_form;footer=partner_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "partner_header": {
                    "light": true
                },
                "partner_registration_form": {
                    "type": "game"
                }
            }
        }
    },
    partner_registration_organization: {
        "default": {
            "dsn": "type_effects;header=partner_header;main=common_dynbody,type_demo_banner,partner_registration_form;footer=partner_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "partner_header": {
                    "light": true
                },
                "partner_registration_form": {
                    "type": "organization"
                }
            }
        }
    },
    partner_registration_owner: {
        "default": {
            "dsn": "type_effects;header=partner_header;main=common_dynbody,type_demo_banner,partner_registration_form;footer=partner_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "partner_header": {
                    "light": true
                },
                "partner_registration_form": {
                    "type": "owner"
                }
            }
        }
    },
    partner_registration_price: {
        "default": {
            "dsn": "type_effects;header=partner_header;main=common_dynbody,type_demo_banner,partner_registration_form;footer=partner_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "partner_header": {
                    "light": true
                },
                "partner_registration_form": {
                    "type": "price"
                }
            }
        }
    },
    partner_registration_summary: {
        "default": {
            "dsn": "type_effects;header=partner_header;main=common_dynbody,type_demo_banner,partner_registration_form;footer=partner_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "partner_header": {
                    "light": true
                },
                "partner_registration_form": {
                    "type": "summary"
                }
            }
        }
    },
}