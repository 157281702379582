import * as pages from './pages';

const routeMap = {
    book_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'book_', ssr: 'book_public', component: pages.book_public},
];

export default {configure: () => ({routeMap, routeList, pages})};