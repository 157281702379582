import PrizesGameModule from "@gotombola/react-ds/lib/template-modules/game/PrizesGameModule";
import React, {useMemo} from "react";
import {Game, Presult} from "@gotombola/sdk";

export function PresultGifts({presult, game, nocontact}: PresultGiftsProps) {
    const wonBunches = useMemo<any>(() => {
        return ((presult.wonPrizesDetails || {}).prizes || []);
    }, [presult.wonPrizesDetails]);

    return (
        <PrizesGameModule {...(('secretdraw' === game?.['type']) ? {notitle: true, nolabel: true} : {})} typeType={'game'} game={game} prizes={wonBunches} nocontact={nocontact} />
    );
}

export interface PresultGiftsProps {
    presult: Presult;
    game: Game;
    nocontact?: boolean;
}

export default PresultGifts;