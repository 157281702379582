import pixel from './pixel';
import push from './push';
import routes from './routes';
import publicPages from "./public-pages";
import templateDefinitions from './template-definitions';

export default {
    pixel,
    publicPages,
    push,
    routes,
    templateDefinitions,
}