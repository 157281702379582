export default {
    game_public: {
        "default": "type_effects;header=game_header;main=type_banner,game_infos,game_stats,game_godfathers,type_images,type_videos,game_cta,type_quote,game_presentation,type_description,game_beneficiaries,type_location_presentation,type_secondary_images,type_secondary_videos,type_agenda,type_detailed_infos,game_featured_bunches,game_organization_presentation,type_video,type_badges,game_cta2,type_tertiary_images,type_tertiary_videos,game_bunches,game_cta3,game_keyfigures,game_labels,game_sponsors,type_share,type_terms,common_dynbody,game_mobilecta,type_demo_banner;footer=game_footer,common_tenant_footer,game_owner_game_welcome",
        "donation_permanent": "type_effects;header=game_header;main=type_banner,game_infos,type_images,type_videos,game_cta,type_quote,game_presentation,type_description,type_secondary_images,type_secondary_videos,game_organization_presentation,type_tertiary_images,type_tertiary_videos,type_video,game_cta2,game_keyfigures,game_labels,type_share,common_dynbody,game_mobilecta,type_demo_banner;footer=game_footer,common_tenant_footer"
    },
    game_share: {
        "default": {
            "dsn": "header=game_header;common_spacer;main=common_title,game_share,common_spacer;type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_share_title"
                }
            }
        }
    },
    game_draw_results: {
        "default": {
            "dsn": "header=game_header;common_spacer;main=common_title,type_demo_banner;footer=game_footer,common_tenant_footer",
            "params": {
                "common_title": {
                    "title": "game_draw_results_title",
                },
                "game_header": {
                    "light": true
                }
            }
        }
    },
    game_draw_live: {
        "default": {
            "dsn": "header=game_header;common_spacer;main=common_title,type_demo_banner;footer=game_footer,common_tenant_footer",
            "params": {
                "common_title": {
                    "title": "game_draw_live_title",
                },
                "game_header": {
                    "light": true
                }
            }
        }
    },
    game_draw_live_direction: {
        "default": {
            "dsn": "header=game_header;common_spacer;main=common_title,type_demo_banner;footer=game_footer,common_tenant_footer",
            "params": {
                "common_title": {
                    "title": "game_draw_live_direction_title",
                },
                "game_header": {
                    "light": true
                }
            }
        }
    },
    game_draw_live_run: {
        "default": {
            "dsn": "header=game_header;common_spacer;main=common_title,type_demo_banner;footer=game_footer,common_tenant_footer",
            "params": {
                "common_title": {
                    "title": "game_draw_live_run_title",
                },
                "game_header": {
                    "light": true
                }
            }
        }
    },
    game_legal: {
        "default": {
            dsn: "header=game_header;common_spacer;main=common_title,game_legal_update_time,game_legal_contact_organization,game_legal_about_website,game_legal_website_provider,game_legal_website_host,game_legal_contact_us,common_spacer;type_demo_banner;footer=game_footer,common_tenant_footer",
            params: {
                common_title: {
                    title: "game_legal_title"
                }
            }
        }
    },
    game_communication: {
        "default": "header=game_header;common_spacer;type_title;main=type_mini_banner,type_about,type_mini_social_networks,common_spacer;aside_right=type_contact_owner,type_sms_keyword,type_qrcode,common_spacer,type_demo_banner;footer=game_footer,common_tenant_footer"
    },
    game_sellergroup_registration_welcome: {
        "default": {
            "dsn": "type_effects;header=game_header;main=common_dynbody,type_demo_banner,game_sellergroup_registration_form;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "game_header": {
                    "light": true
                },
                "game_sellergroup_registration_form": {
                    "type": "welcome"
                }
            }
        }
    },
    game_sellergroup_registration_team: {
        "default": {
            "dsn": "type_effects;header=game_header;main=common_dynbody,type_demo_banner,game_sellergroup_registration_form;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "game_header": {
                    "light": true
                },
                "game_sellergroup_registration_form": {
                    "type": "team"
                }
            }
        }
    },
    game_sellergroup_registration_owner: {
        "default": {
            "dsn": "type_effects;header=game_header;main=common_dynbody,type_demo_banner,game_sellergroup_registration_form;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "game_header": {
                    "light": true
                },
                "game_sellergroup_registration_form": {
                    "type": "owner"
                }
            }
        }
    },
    game_sellergroup_registration_summary: {
        "default": {
            "dsn": "type_effects;header=game_header;main=common_dynbody,type_demo_banner,game_sellergroup_registration_form;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "game_header": {
                    "light": true
                },
                "game_sellergroup_registration_form": {
                    "type": "summary"
                }
            }
        }
    },
    game_leaderboard: {
        "default": {
            "dsn": "header=game_header;common_spacer;main=game_leaderboard,common_spacer,type_demo_banner;footer=game_footer,common_tenant_footer",
            "params": {
                "game_leaderboard": {
                    "defaultFilter": "full"
                }
            }
        }
    },
    game_godfathers_ranking: {
        "default": {
            "dsn": "header=game_header;common_spacer;main=game_godfathers_ranking,common_spacer,type_demo_banner;footer=game_footer,common_tenant_footer",
        }
    },
    game_files: {
        "default": "header=game_header;common_spacer;main=game_files,common_spacer,type_demo_banner;footer=game_footer,common_tenant_footer",
    },
    game_account_link_request: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_account_link_request_title",
                },
            },
        },
    },
    game_inbox: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_inbox_title",
                },
            },
        },
    },
    game_terms_and_conditions: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,type_demo_banner;footer=game_footer,common_tenant_footer",
            "params": {
                "common_title": {
                    "title": "game_terms_and_conditions_title",
                },
            },
        },
    },
}