import * as pages from './pages';

const routeMap = {
    game_buy: {
        en_US: '/',
    },
    game_buy_external_payment: {
        de_DE: '/externe-zahlnung',
        en_GB: '/external-payment',
        en_US: '/external-payment',
        es_ES: '/pago-externo',
        fr_BE: '/paiement-externe',
        fr_CA: '/paiement-externe',
        fr_CH: '/paiement-externe',
        fr_FR: '/paiement-externe',
        fr_LU: '/paiement-externe',
        fr_MC: '/paiement-externe',
        it_IT: '/pagamento-esterno',
        nl_BE: '/externe-betaling',
        pt_BR: '/pagamento-externo',
        pt_PT: '/pagamento-externo',
    },
    game_buy_payment: {
        de_DE: '/zahlung',
        en_GB: '/payment',
        en_US: '/payment',
        es_ES: '/pago',
        fr_BE: '/paiement',
        fr_CA: '/paiement',
        fr_CH: '/paiement',
        fr_FR: '/paiement',
        fr_LU: '/paiement',
        fr_MC: '/paiement',
        it_IT: '/pagamento',
        nl_BE: '/betaling',
        pt_BR: '/pagamento',
        pt_PT: '/pagamento',
    },
    game_buy_bonus: {
        de_DE: '/bonus',
        en_GB: '/bonus',
        en_US: '/bonus',
        es_ES: '/bono',
        fr_BE: '/bonus',
        fr_CA: '/bonus',
        fr_CH: '/bonus',
        fr_FR: '/bonus',
        fr_LU: '/bonus',
        fr_MC: '/bonus',
        it_IT: '/bonus',
        nl_BE: '/bonus',
        pt_BR: '/bonus',
        pt_PT: '/bonus',
    },
    game_buy_owner: {
        de_DE: '/kaufer',
        en_GB: '/owner',
        en_US: '/owner',
        es_ES: '/comprador',
        fr_BE: '/acheteur',
        fr_CA: '/acheteur',
        fr_CH: '/acheteur',
        fr_FR: '/acheteur',
        fr_LU: '/acheteur',
        fr_MC: '/acheteur',
        it_IT: '/acquirente',
        nl_BE: '/koper',
        pt_BR: '/comprador',
        pt_PT: '/comprador',
    },
    game_buy_request: {
        de_DE: '/anfrage',
        en_GB: '/request',
        en_US: '/request',
        es_ES: '/solicitar',
        fr_BE: '/requete',
        fr_CA: '/requete',
        fr_CH: '/requete',
        fr_FR: '/requete',
        fr_LU: '/requete',
        fr_MC: '/requete',
        it_IT: '/richiesta',
        nl_BE: '/verzoek',
        pt_BR: '/solicitacao',
        pt_PT: '/pedido',
    },
    game_buy_quantity: {
        de_DE: '/menge',
        en_GB: '/quantity',
        en_US: '/quantity',
        es_ES: '/cantidad',
        fr_BE: '/quantite',
        fr_CA: '/quantite',
        fr_CH: '/quantite',
        fr_FR: '/quantite',
        fr_LU: '/quantite',
        fr_MC: '/quantite',
        it_IT: '/quantita',
        nl_BE: '/hoeveelheid',
        pt_BR: '/quantidade',
        pt_PT: '/quantidade',
    },
    game_buy_donation: {
        de_DE: '/Spende',
        en_GB: '/donation',
        en_US: '/donation',
        es_ES: '/donación',
        fr_BE: '/donation',
        fr_CA: '/donation',
        fr_CH: '/donation',
        fr_FR: '/donation',
        fr_LU: '/donation',
        fr_MC: '/donation',
        it_IT: '/donazione',
        nl_BE: '/donatie',
        pt_BR: '/doação',
        pt_PT: '/doação',
    },
    game_buy_beneficiary: {
        de_DE: '/empfanger',
        en_GB: '/beneficiary',
        en_US: '/beneficiary',
        es_ES: '/beneficiario',
        fr_BE: '/beneficiaire',
        fr_CA: '/beneficiaire',
        fr_CH: '/beneficiaire',
        fr_FR: '/beneficiaire',
        fr_LU: '/beneficiaire',
        fr_MC: '/beneficiaire',
        it_IT: '/beneficiario',
        nl_BE: '/begungstigde',
        pt_BR: '/beneficiario',
        pt_PT: '/beneficiario',
    },
    game_buy_project: {
        de_DE: '/projekt',
        en_GB: '/project',
        en_US: '/project',
        es_ES: '/proyecto',
        fr_BE: '/projet',
        fr_CA: '/projet',
        fr_CH: '/projet',
        fr_FR: '/projet',
        fr_LU: '/projet',
        fr_MC: '/projet',
        it_IT: '/progetto',
        nl_BE: '/project',
        pt_BR: '/projecto',
        pt_PT: '/projecto',
    },
    game_buy_seller_group_groupings: {
        de_DE: '/kategorie-der-verkaufergruppe',
        en_GB: '/seller-group-groupings',
        en_US: '/seller-group-groupings',
        es_ES: '/categoria-de-grupo-de-vendedores',
        fr_BE: '/categorie-de-groupe-de-vendeurs',
        fr_CA: '/categorie-de-groupe-de-vendeurs',
        fr_CH: '/categorie-de-groupe-de-vendeurs',
        fr_FR: '/categorie-de-groupe-de-vendeurs',
        fr_LU: '/categorie-de-groupe-de-vendeurs',
        fr_MC: '/categorie-de-groupe-de-vendeurs',
        it_IT: '/categoria-del-gruppo-di-venditori',
        nl_BE: '/categorie-van-de-verkopersgroep',
        pt_BR: '/categorie-de-grupo-de-fornecedores',
        pt_PT: '/categorie-de-grupo-de-fornecedores',
    },
    game_buy_seller_group: {
        de_DE: 'verkaufergruppe',
        en_GB: '/seller-group',
        en_US: '/seller-group',
        es_ES: '/grupo-de-proveedores',
        fr_BE: '/groupe-de-vendeurs',
        fr_CA: '/groupe-de-vendeurs',
        fr_CH: '/groupe-de-vendeurs',
        fr_FR: '/groupe-de-vendeurs',
        fr_LU: '/groupe-de-vendeurs',
        fr_MC: '/groupe-de-vendeurs',
        it_IT: '/gruppo-di-venditori',
        nl_BE: '/leveranciersgroep',
        pt_BR: '/grupo-de-vendas',
        pt_PT: '/grupo-de-vendas',
    },
    game_buy_seller: {
        de_DE: '/verkaufer',
        en_GB: '/seller',
        en_US: '/seller',
        es_ES: '/proveedor',
        fr_BE: '/vendeur',
        fr_CA: '/vendeur',
        fr_CH: '/vendeur',
        fr_FR: '/vendeur',
        fr_LU: '/vendeur',
        fr_MC: '/vendeur',
        it_IT: '/venditore',
        nl_BE: '/verkoper',
        pt_BR: '/fornecedor',
        pt_PT: '/fornecedor',
    },
    game_buy_custom: {
        de_DE: '/zusatzinformationen',
        en_GB: '/custom',
        en_US: '/custom',
        es_ES: '/informacion-adicional',
        fr_BE: '/informations-complementaires',
        fr_CA: '/informations-complementaires',
        fr_CH: '/informations-complementaires',
        fr_FR: '/informations-complementaires',
        fr_LU: '/informations-complementaires',
        fr_MC: '/informations-complementaires',
        it_IT: '/informazioni-aggiuntive',
        nl_BE: '/aanvullende-informatie',
        pt_BR: '/informacao-adicional',
        pt_PT: '/informacao-adicional',
    },
    game_buy_custom_quantity: {
        de_DE: '/kundenspezifische-menge',
        en_GB: '/custom-quantity',
        en_US: '/custom-quantity',
        es_ES: '/cantidad-personalizada',
        fr_BE: '/quantite-personnalisee',
        fr_CA: '/quantite-personnalisee',
        fr_CH: '/quantite-personnalisee',
        fr_FR: '/quantite-personnalisee',
        fr_LU: '/quantite-personnalisee',
        fr_MC: '/quantite-personnalisee',
        it_IT: '/quantita-personalizzata',
        nl_BE: '/aangepaste-hoeveelheid',
        pt_BR: '/quantidade-personalizada',
        pt_PT: '/quantidade-personalizada',
    },
    game_buy_privacy: {
        // de_DE: '/zusatzinformationen',
        en_GB: '/privacy',
        en_US: '/privacy',
        // es_ES: '/informacion-adicional',
        fr_BE: '/confidentialite',
        fr_CA: '/confidentialite',
        fr_CH: '/confidentialite',
        fr_FR: '/confidentialite',
        fr_LU: '/confidentialite',
        fr_MC: '/confidentialite',
        // it_IT: '/informazioni-aggiuntive',
        // nl_BE: '/aanvullende-informatie',
        // pt_BR: '/informacao-adicional',
        // pt_PT: '/informacao-adicional',
    },
}

const routeList = [
    {name: 'game_buy', path: '/', exact: true, redirect: '/quantity'},
    'game_buy_external_payment',
    'game_buy_payment',
    'game_buy_bonus',
    'game_buy_owner',
    'game_buy_request',
    'game_buy_quantity',
    'game_buy_beneficiary',
    'game_buy_project',
    'game_buy_seller_group_groupings',
    'game_buy_seller_group',
    'game_buy_seller',
    'game_buy_custom',
    'game_buy_privacy',
    'game_buy_donation',
    'game_buy_custom_quantity',
];

export default {configure: () => ({routeMap, routeList, pages})};
