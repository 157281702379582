import pixel from './pixel';
import publicPages from './public-pages';
import routes from './routes';
import workflows from './workflows';
import actions from './actions';
import templateDefinitions from './template-definitions';

export default {
    pixel,
    publicPages,
    routes,
    workflows,
    actions,
    templateDefinitions,
}