import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import React, {useEffect, useMemo} from "react";
import useGameBuyQuantities from "../../../hooks/useGameBuyQuantities";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import Spinner from "@gotombola/react-ds/lib/atoms/Spinner";

export function QuantityBuyGameForm({ game, locale, workflows }: QuantityBuyGameFormProps) {
    const {t} = useTypeTranslation('game', game);
    const items = useGameBuyQuantities(game, { t, locale, workflows })

    const redirectUri = useMemo(() => !!game?.features?.includes('hide_buy_sale_quantity_screen') && items?.[0].target, [game?.features, items?.[0].target]);

    useEffect(() => {
        redirectUri && 'undefined' !== typeof window && (window.location.href = redirectUri);
    }, []);

    if (redirectUri) {
        return <Spinner />;
    }

    return (
        <ChoiceMenu items={items} />
    )
}

export interface QuantityBuyGameFormProps {
    game: any;
    workflows: any;
    locale: string;
}

export default QuantityBuyGameForm;
