import * as pages from './pages';

const routeMap = {
    game_buy_book: {
        en_US: '/',
    },
    game_buy_book_external_payment: {
        de_DE: '/externe-zahlnung',
        en_GB: '/external-payment',
        en_US: '/external-payment',
        es_ES: '/pago-externo',
        fr_BE: '/paiement-externe',
        fr_CA: '/paiement-externe',
        fr_CH: '/paiement-externe',
        fr_FR: '/paiement-externe',
        fr_LU: '/paiement-externe',
        fr_MC: '/paiement-externe',
        it_IT: '/pagamento-esterno',
        nl_BE: '/externe-betaling',
        pt_BR: '/pagamento-externo',
        pt_PT: '/pagamento-externo',
    },
    game_buy_book_payment: {
        de_DE: '/zahlung',
        en_GB: '/payment',
        en_US: '/payment',
        es_ES: '/pago',
        fr_BE: '/paiement',
        fr_CA: '/paiement',
        fr_CH: '/paiement',
        fr_FR: '/paiement',
        fr_LU: '/paiement',
        fr_MC: '/paiement',
        it_IT: '/pagamento',
        nl_BE: '/betaling',
        pt_BR: '/pagamento',
        pt_PT: '/pagamento',
    },
    game_buy_book_bonus: {
        de_DE: '/bonus',
        en_GB: '/bonus',
        en_US: '/bonus',
        es_ES: '/bono',
        fr_BE: '/bonus',
        fr_CA: '/bonus',
        fr_CH: '/bonus',
        fr_FR: '/bonus',
        fr_LU: '/bonus',
        fr_MC: '/bonus',
        it_IT: '/bonus',
        nl_BE: '/bonus',
        pt_BR: '/bonus',
        pt_PT: '/bonus',
    },
    game_buy_book_owner: {
        de_DE: '/kaufer',
        en_GB: '/owner',
        en_US: '/owner',
        es_ES: '/comprador',
        fr_BE: '/acheteur',
        fr_CA: '/acheteur',
        fr_CH: '/acheteur',
        fr_FR: '/acheteur',
        fr_LU: '/acheteur',
        fr_MC: '/acheteur',
        it_IT: '/acquirente',
        nl_BE: '/koper',
        pt_BR: '/comprador',
        pt_PT: '/comprador',
    },
}

const routeList = [
    {name: 'game_buy_book', path: '/', exact: true, redirect: '/owner'},
    'game_buy_book_external_payment',
    'game_buy_book_payment',
    'game_buy_book_bonus',
    'game_buy_book_owner',
];

export default {configure: () => ({routeMap, routeList, pages})};
