import * as pages from './pages';

const routeMap = {
    campaign_: {
        en_US: '/:slug?',
    }
}

const routeList = [
    {name: 'campaign_', ssr: 'campaign_public', component: pages.campaign_public},
];

export default {configure: () => ({routeMap, routeList, pages})};