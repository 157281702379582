import React, {useMemo} from "react";
import clsx from "clsx";
import SaleAd from "./SaleAd";
import SaleDetails from "./SaleDetails";
import SaleStatusPanel from "./SaleStatusPanel";
import SaleAttributions from "./SaleAttributions";
import SaleOwnedTickets from "./SaleOwnedTickets";
import {GameContextProvider} from '@gotombola/react-contexts-type/lib/contexts/GameContext';

export function DisplaySaleGameForm({ game, sale }: DisplaySaleGameFormProps) {
    const props = useMemo(() => ({game, sale}), [game, sale]);
    const gcp = useMemo(() => ({game}), [game]);

    return (
        <div className={'mx-auto w-full sm:w-3/4 md:w-2/3 mt-6 sm:mt-10 items-center justify-center flex flex-col'}>
            <div className={clsx("flex flex-col w-full px-4 sm:px-0 space-y-6 sm:space-y-10 mb-20")}>
                <SaleStatusPanel {...props} />
                <GameContextProvider value={gcp}>
                    <SaleAd {...props} />
                </GameContextProvider>
                <SaleAttributions {...props} />
                <SaleOwnedTickets {...props} />
                <SaleDetails {...props} />
            </div>
        </div>
    );
}

export interface DisplaySaleGameFormProps {
    game: any;
    sale: any;
}

export default DisplaySaleGameForm;
