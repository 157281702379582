import React from 'react';
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import BasicStaticPage from "@gotombola/react-ds/lib/pages/BasicStaticPage";

// noinspection JSUnusedGlobalSymbols
export default function CountryHomePage({mode}: any) {
    const {pixel} = useMode(mode);

    return (
        <BasicStaticPage noindex>
            <Pixel app={pixel.app} event={pixel.pages.error_country_home} />
        </BasicStaticPage>
    );
}