import React from 'react';
import Ads from "../../../atoms/Ads";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import useGameContext from '@gotombola/react-contexts-type/lib/hooks/useGameContext';
import {Presult} from "@gotombola/sdk";

export function PresultPublicPageBodyAdZone({ads, presult}: PresultPublicPageBodyAdZoneProps) {
    const {game} = useGameContext();
    const {t} = useTypeTranslation('game', game);

    if (!ads?.length) return null;

    return (
        <div className={'w-full flex flex-col gap-6 py-4'}>
            <div className={'w-full text-center font-light bg-green-100 rounded-md p-2 space-y-2'}>
                <p className={'font-bold text-2xl'}>{t(!!presult.winningTicketCount ? 'presult_public_page_body_ad_zone_text_winner' : 'presult_public_page_body_ad_zone_text_looser')}</p>
                <p className={'text-lg'}>{t('presult_public_page_body_ad_zone_text2')}</p>
            </div>
            <Ads ads={ads} format={'image'} className={'w-full flex flex-col gap-4'} />
        </div>
    );
}

export interface PresultPublicPageBodyAdZoneProps {
    ads?: any[];
    presult: Presult
}

export default PresultPublicPageBodyAdZone;