import React, {useMemo} from 'react'
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import DisplayPreorderGameForm from "../molecules/DisplayPreorderGameForm";

const PreorderPublicPage = editablePage('game', [], ({staticContext: {game, preorder, locale, mode}}: any) => {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code, poi: preorder.id}), [game.code, preorder.id]);
    const pushProps = useMemo(() => ({mode: game.status, game, preorder}), [game, game.status, preorder]);

    return (
        <GamePageWrapper game={game} locale={locale}>
            <TypePage type={'game'} doc={game} page={'preorder_public'} locale={locale}
                      publicPages={publicPages} templates={templates}
                      pixels={pixel} pushs={push}
                      pixelProps={pixelProps} pushProps={pushProps}
                      noindex
            >
                <DisplayPreorderGameForm game={game} preorder={preorder} />
                <Pixel app={pixel.app} event={pixel.pages.preorder_public} />
            </TypePage>
        </GamePageWrapper>
    );
});

export default PreorderPublicPage;
