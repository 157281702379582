import React, {useMemo} from 'react'
import {useMode} from "../../../utils/common";
import TypePage from '@gotombola/react-ds/lib/pages/TypePage';
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";

const SponsorPublicPage = editablePage('sponsor', [], ({staticContext: {sponsor, locale, mode}}: any) => {
    const {pixel, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({sc: sponsor.code}), [sponsor.code]);

    return (
        <TypePage type={'sponsor'} doc={sponsor} page={'public'} locale={locale}
                  publicPages={publicPages} templates={templates}
                  pixels={pixel} pixelProps={pixelProps}
        />
    );
});

export default SponsorPublicPage;