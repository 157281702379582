import React from "react";
import GameDrawResults from "@gotombola/react-ds/lib/organisms/GameDrawResults";
import SponsorsTypeModule from "@gotombola/react-ds/lib/template-modules/type/SponsorsTypeModule";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function DisplayDrawResultsGameForm({ game, draw }: DisplayDrawResultsGameFormProps) {
    const { t } = useTypeTranslation('game', game);
    const areResultsPublished = 'PUBLISHED' === game.resultsPublishingStatus;
    const customResultsPage = game.customResultsPageUrl;

    return (
        <div className={'flex flex-col items-center w-full px-4 md:px-16 sm:pb-4 mb-24 space-y-4 sm:space-y-10 mx-auto'}>
            {areResultsPublished && !customResultsPage && !!draw?.winnersCount && <GameDrawResults game={game} draw={draw} />}
            {areResultsPublished && !!customResultsPage && (
                <a
                    href={customResultsPage}
                    className={
                        'font-go-button text-white text-lg text-center p-2 rounded-md cursor-pointer hover:bg-go-secondary hover:text-white bg-go-primary'
                    }
                >
                    {t('game_draw_results_customresultspage_button_label')}
                </a>
            )}
            {!areResultsPublished && <div className={'font-go-text text-go-text text-xl'}>{t('game_draw_results_unpublished_results_text')}</div>}
            <SponsorsTypeModule typeType={'game'} doc={game} />
        </div>
    );
}

export interface DisplayDrawResultsGameFormProps {
    game: any;
    draw: any;
}

export default DisplayDrawResultsGameForm;
