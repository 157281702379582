import React, {useMemo} from 'react'
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import BonusBuyGameForm from "../molecules/BonusBuyGameForm";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import SecondaryMessage from "../../../atoms/SecondaryMessage";
import TermsLink from "../../../atoms/TermsLink";

export const GameBuyBonusPage = editablePage('game', [], ({staticContext: {game, locale, mode}}: any) => {
    const {pixel, push, publicPages, templates, workflows} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);

    return (
        <GamePageWrapper game={game} locale={locale}>
            <TypePage type={'game'} doc={game} page={'buy_bonus'} locale={locale}
                      publicPages={publicPages} templates={templates}
                      pixels={pixel} pushs={push}
                      pixelProps={pixelProps} pushProps={pushProps}
                      noindex
            >
                <BonusBuyGameForm game={game} workflows={workflows} />
                <SecondaryMessage message={game?.buyBonusSecondaryMessage} />
                <TermsLink url={game?.termsPdfFile?.url} />
                <Pixel app={pixel.app} event={pixel.pages.game_buy_bonus} gc={game.code} />
            </TypePage>
        </GamePageWrapper>
    );
});

export default GameBuyBonusPage;
