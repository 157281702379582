import React, {useCallback, useState} from 'react'
import clsx from 'clsx';
import {cleanValue} from "../../../utils/cleaner";
import Error from "@gotombola/react-ds/lib/molecules/Error";
import Spinner2 from "@gotombola/react-ds/lib/atoms/Spinner2";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import useHttpCallableQuery from "@gotombola/react-ds/lib/hooks/useHttpCallableQuery";

const AccountLinkRequestGameForm = ({ game }: AccountLinkRequestGameFormProps) => {
  const { t } = useTypeTranslation('game', game);
  const [state, setState] = useState<{email: string, step: string, error?: {message: string}}>({email: '', step: 'form', error: undefined});

  const timestamp = new Date().valueOf();
  const [requestAccountLink, {loading}] = useHttpCallableQuery(`/api/games/${game.code}/account-links?_=${timestamp}`, 'POST');
  const handleChangeEmail = (event: any) => {
    setState({...state, email: cleanValue('email', event.target.value)})
  }
  const handleSubmit = useCallback(() => {
    requestAccountLink({variables: {email: state.email}}).then(({data}: any) => {
      if (data?.status !== 'success') {
        setState({...state, step: 'form', error: {message: data?.error?.message}});
      } else {
        setState({...state, step: 'confirmation'});
      }
    }).catch ((e: any) => {
      setState({...state, step: 'form', error: {message: e.message}});
    })}, [requestAccountLink, state, setState]);
  const colors = 'border-go-choice-secondary bg-go-choice-secondary text-go-choice-primary hover:bg-go-choice-primary hover:text-go-choice-secondary';
  const isButtonDisabled = !state.email || loading;

  return (
    <div className='flex flex-col justify-center items-center'>
      <div className='flex flex-col justify-center items-center gap-6'>
        <p className='font-go-text text-go-text text-center max-w-xl'>
          {t('game_account_link_request_page_description')}
        </p>
        {'form' === state.step && (
            <>
              {state?.error?.message && <Error error={state?.error as any} />}
              {loading && <Spinner2 />}
              <input
                onChange={handleChangeEmail}
                value={state.email}
                placeholder={t('field_email_placeholder')}
                className={clsx("font-go-text text-go-text border p-3 rounded-md w-full")}
                autoFocus
                disabled={loading}
              />
              <button
                type={"button"}
                onClick={handleSubmit}
                className={clsx("font-go-button w-full p-3 border disabled:border-gray-300 disabled:text-white disabled:bg-gray-300 rounded-md text-xl", !isButtonDisabled && colors)}
                disabled={isButtonDisabled}
              >
                {t('game_account_link_request_page_submit_label')}
              </button>
            </>
        )}
        {'confirmation' === state.step && (
            <p className='font-go-text text-go-text text-center max-w-xl'>
              {t('game_account_link_request_page_confirmation_description')}
            </p>
        )}
      </div>
    </div>
  )
}

export interface AccountLinkRequestGameFormProps {
  game: any;
}

export default AccountLinkRequestGameForm;