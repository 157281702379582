import React from 'react';
import useTrans from "@gotombola/react-i18n/lib/hooks/useTrans";
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import useBase64QueryStringParam from "@gotombola/react-ds/lib/hooks/useBase64QueryStringParam";
import StaticIcon from "@gotombola/react-ds/lib/atoms/StaticIcon";
import Button from "@gotombola/react-ds/lib/atoms/Button";
import ServiceFooter from "@gotombola/react-ds/lib/organisms/ServiceFooter";
import StaticPage from "@gotombola/react-ds/lib/pages/StaticPage";
import Text from "@gotombola/react-ds/lib/atoms/Text";

// noinspection JSUnusedGlobalSymbols
export default function ErrorCodePage({staticContext: {error, mode}}: any) {
    const {pixel} = useMode(mode)
    const {t} = useTrans();
    const m = useBase64QueryStringParam('m');
    const defaultIcon = 'exclamation';
    error = error || {code: 404, title: 'errors_404_title', description: 'errors_404_description'};
    const description = t([`errors_${error.name}_description`, `errors_${error.kind || ''}_description`, ''])
    const title = t([`errors_${error.name}_title`, `errors_${error.kind || ''}_title`, ''])
    const target = `mailto:contact@gotombola.co?subject=${(title || '')}`;

    return (
        <StaticPage title={'GoTombola'} noindex>
            <div className={"h-full flex flex-col items-center text-center mt-10 p-4"}>
                <StaticIcon type={error.icon || defaultIcon} className={'w-40 h-40 text-gray-700'} />
                <Text extralight className={'my-6 font-gray-300'}>{t('error_page_code', {code: error.code})}</Text>
                <Text semibold className={'mb-2 text-2xl'}>{title}</Text>
                {!!description && <Text className={'mb-2 font-gray-300 max-w-5xl'}>{description}</Text>}
                {!!m && <Text className={'text-xs my-4 text-gray-400'}>{m}</Text>}
                <Button target={target} className={'w-full sm:w-auto my-4 sm:my-8 px-6 sm:px-8 py-4 text-lg rounded-md font-bold text-center'} label={t('error_page_contact_label')} />
            </div>
            <ServiceFooter />
            <Pixel app={pixel.app} event={pixel.pages.error_error_code} ec={error.code} />
        </StaticPage>
    );
}