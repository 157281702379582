import React from 'react';
import SimplePage from "@gotombola/react-ds/lib/pages/SimplePage";
import {useMode} from "../../../utils/common";

export default function GenericPage({staticContext: {mode}}: any) {
    const {pixel} = useMode(mode);

    return (
        <SimplePage page={'generic'} pixels={pixel} />
    );
}