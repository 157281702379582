export default {
    game_buy_beneficiary: 'a1.x418',
    game_buy_bonus: 'a1.x417',
    game_buy_owner: 'a1.x411',
    game_buy_quantity: 'a1.x402',
    game_buy_custom_quantity: 'a1.x425',
    game_buy_payment: 'a1.x412',
    game_buy_request: 'a1.x413',
    game_buy_custom: 'a1.x414',
    game_buy_project: 'a1.x415',
    game_buy_external_payment: 'a1.x416',
    game_buy_seller_group: 'a1.x419',
    game_buy_seller_group_groupings: 'a1.x422',
    game_buy_seller: 'a1.x420',
    game_info_buyer: 'a1.x421',
    game_info_privacy: 'a1.x423',
    game_info_donations: 'a1.x424',
}