import React, {useCallback} from "react";
import clsx from "clsx";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

const colors = 'border-go-choice-secondary bg-go-choice-secondary text-go-choice-primary hover:bg-go-choice-primary hover:text-go-choice-secondary';

export function GamePaymentBlockedPanel({ game }: GamePaymentBlockedPanelProps) {
    const {t} = useTypeTranslation('game', game);

    const onContactClick = useCallback((e: any) => {
        e.stopPropagation();
        e.preventDefault();
        window.location.href = `mailto:${game.organizationOwnerEmail}?subject=${t('game_buy_payment_page_blocked_contact_subject')}&body=Page de la tombola : ${game.publicPageShortUrl}`;
    }, [game.organizationOwnerEmail, t, game.publicPageShortUrl]);

    return (
        <div className={'w-full space-y-4'}>
            <div
                className={'font-go-text text-center w-full text-red-500 text-lg'}>{t('game_buy_payment_page_blocked_message')}</div>
            <div className={'w-full flex pt-4'}>
                <button
                    type={"button"}
                    className={clsx("font-go-button w-full p-3 border disabled:border-gray-300 disabled:text-white disabled:bg-gray-300 rounded-md text-xl", colors)}
                    onClick={onContactClick}
                >
                    {t('game_buy_payment_page_contact_button_label')}
                </button>
            </div>
        </div>
    );
}

export interface GamePaymentBlockedPanelProps {
    game: any;
}

export default GamePaymentBlockedPanel;
