import * as pages from './pages';

const routeMap = {
    order_: {
        en_US: '/',
    },
}

const routeList = [
    {name: 'order_', ssr: 'order_public', component: pages.order_public},
];

export default {configure: () => ({routeMap, routeList, pages})};