import React, {useMemo} from "react";
import zones from '../ad-zones';
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import DisplaySaleGameForm from "../molecules/DisplaySaleGameForm";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import {AdZonesProvider} from "@gotombola/react-ad/lib/AdZonesProvider";
import PushNotification from "@gotombola/react-ds/lib/atoms/PushNotification";

export const SalePublicPage = editablePage('game', [], ({staticContext: {sale, game, locale, mode, ad}}: any) => {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);

    return (
        <AdZonesProvider components={zones} settings={ad}>
            <GamePageWrapper game={game} locale={locale}>
                <TypePage type={'game'} doc={game} page={'sale_public'} locale={locale}
                          publicPages={publicPages} templates={templates}
                          pixels={pixel} pushs={push}
                          pixelProps={pixelProps} pushProps={pushProps}
                          noindex
                >
                    <DisplaySaleGameForm game={game} sale={sale} />
                    <Pixel app={pixel.app} event={pixel.pages.sale_public} gc={game.code} />
                    <PushNotification app={push.app} type={push.pages.sale_public} mode={game.status} game={game} sale={sale} />
                </TypePage>
            </GamePageWrapper>
        </AdZonesProvider>
    );
});

export default SalePublicPage;
