import React from "react";
import usePageTypeTranslation from "@gotombola/react-ds/lib/hooks/usePageTypeTranslation";
import SectionTitle from "@gotombola/react-ds/lib/atoms/SectionTitle";

export function TermsAndConditionsGameForm({ game }: TermsAndConditionsGameFormProps) {
    const {t} = usePageTypeTranslation('terms_and_conditions', 'game', game);
    const hasTermsPdfFile = (!!game.termsPdfFile && game.termsPdfFile.available);

    return (
        <>
            {hasTermsPdfFile ? (
                <iframe title={'terms'} className={'w-full'} src={`${game.termsPdfFile.url}#toolbar=1&navpanes=1&scrollbar=1`} width={'100%'} height={800} />
            ) : (
                <div className={'w-full flex flex-col gap-10 items-center justify-center p-10 mb-20'}>
                    <SectionTitle title={t('title')} />
                    {t('text')}
                </div>
            )}
        </>
    );
}

export interface TermsAndConditionsGameFormProps {
    game: any;
}

export default TermsAndConditionsGameForm;
