export default {
    partner_register__default: {
        steps: {
            start: {next: 'partner_register_website'},
            website: {url: 'partner_register_website', next: 'partner_registration:start', nextFn: ({extraVars}: {doc: any, extraVars: any}) => {
                return ({doc: extraVars?.data, extraVars: {}});
            }},
        }
    },
    partner_registration__default: {
        steps: {
            start: {url: 'partner_registration', redirect: 'organization', ssr: true},
            organization: {url: 'partner_registration_organization', ssr: true, next: 'owner', nextFn: ({extraVars}: {doc: any, extraVars: any}) => {
                    return ({doc: extraVars?.data, extraVars: {}});
            }},
            owner: {url: 'partner_registration_owner', next: 'game', nextFn: ({extraVars}: {doc: any, extraVars: any}) => {
                    return ({doc: extraVars?.data, extraVars: {}});
                }},
            game: {url: 'partner_registration_game', next: 'partner_registration_complete:summary', nextFn: ({extraVars}: {doc: any, extraVars: any}) => {
                    return ({doc: extraVars?.data, extraVars: {}});
                }},
        },
    },
    partner_registration_complete__default: {
        steps: {
            summary: {url: 'partner_registration_summary', ssr: true},
        },
    },
};
