import React, {useMemo} from 'react';
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import PushNotification from "@gotombola/react-ds/lib/atoms/PushNotification";
import TermsAndConditionsGameForm from "../molecules/TermsAndConditionsGameForm";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";

const GameTermsAndConditionsPage = editablePage('game', [], ({staticContext: {game, locale, mode}}: any) => {
    const {pixel, push, publicPages, templates, workflows} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);

    return (
        <GamePageWrapper game={game} locale={locale}>
            <TypePage type={'game'} doc={game} page={'terms_and_conditions'} locale={locale}
                      publicPages={publicPages} templates={templates}
                      pixels={pixel} pushs={push}
                      pixelProps={pixelProps} pushProps={pushProps}
            >
                <TermsAndConditionsGameForm game={game} />
                <Pixel app={pixel.app} event={pixel.pages.game_terms_and_conditions} gc={game.code} />
                <PushNotification app={push.app} type={push.pages.game_terms_and_conditions} mode={game.status} game={game} />
            </TypePage>
        </GamePageWrapper>
    );
});

export default GameTermsAndConditionsPage;
