import * as pages from './pages';

const routeMap = {
    organization_: {
        en_US: '/',
    }
}

const routeList = [
    {name: 'organization_', ssr: 'organization_public', component: pages.organization_public},
];

export default {configure: () => ({routeMap, routeList, pages})};