import React, {useMemo} from 'react'
import useTrans from "@gotombola/react-i18n/lib/hooks/useTrans";
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import StaticPage from "@gotombola/react-ds/lib/pages/StaticPage";
import StaticIcon from "@gotombola/react-ds/lib/atoms/StaticIcon";
import ServiceFooter from "@gotombola/react-ds/lib/organisms/ServiceFooter";
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";

export default function OrderPublicPage({staticContext: {order, mode}}: any) {
    const {pixel} = useMode(mode);
    const {t} = useTrans();
    const tenantCode = order.tenantCode || 'gotombola'; // @todo when pb is fixed, remove default value
    const orderCode = order.code;
    const url = useMemo(() => {
        return `${process.env.RAZZLE_GOTOMBOLA_API_PAYMENT_ENDPOINT}/payments/new/tenant-order?t=${tenantCode}&q=${orderCode}`;
    }, [tenantCode, orderCode]);

    return (
        <StaticPage noindex>
            <div className={'bg-blue-500 h-80 top-0 left-0 right-0 absolute z-0 shadow-md px-8'} />
            <div className={'w-full z-20 relative mt-20 flex justify-center items-center text-white space-x-6'}>
                <StaticIcon type={'ticket'} className={'w-20 h-20'} />
                <div>
                    <div className={'font-go-text text-go-text font-bold text-4xl'}>{t('order_public_page_already_15_sales')}</div>
                    <div className={'font-go-text text-go-text text-3xl'}>{t('order_public_page_sell_more')}</div>
                </div>
            </div>
            <div className={'w-full flex flex-wrap justify-center text-center z-20 relative mt-14 px-10'}>
                <div className={'w-2/3 sm:1/3 md:w-2/5 lg:w-1/6 my-2 mx-6 space-y-2'}>
                    <div className={'border border-gray-300 rounded-xl w-full h-80 bg-white'}>
                        <div className={'mx-auto rounded-full bg-blue-100 w-20 h-20 mt-10'} />
                        <div className={'font-go-text w-min mx-auto z-20 -mt-4 p-0.5 text-blue-500 font-base text-base bg-gray-100 rounded-md invisible'}>{t('order_public_page_sales')}</div>
                        <div className={'font-go-text text-go-text font-semibold text-5xl mt-6'}>{formatRawAmount(0, order.currency)}</div>
                        <div className={'font-go-text text-go-text font-semibold text-yellow-500 mt-6'}>{t('order_public_page_only_5_sales_left')}</div>
                    </div>
                    <a className={'block'}><button className={'font-go-button border border-gray-300 rounded-xl w-full h-16 bg-white text-lg font-bold text-center cursor-default'}>{t('order_public_page_current_choice')}</button></a>
                </div>
                <div className={'w-2/3 sm:1/3 md:w-2/5 lg:w-1/6 my-2 mx-6 space-y-2'}>
                    <div className={'border border-gray-300 rounded-xl w-full h-80 bg-white'}>
                        <div className={'font-go-text mx-auto rounded-full bg-blue-100 w-20 h-20 mt-10 text-blue-500 flex items-center justify-center text-3xl font-medium z-10'}>+30</div>
                        <div className={'font-go-text w-min mx-auto z-20 -mt-4 p-0.5 text-blue-500 font-base text-base bg-gray-100 rounded-md'}>{t('order_public_page_sales')}</div>
                        <div className={'font-go-text text-go-text font-semibold text-5xl mt-6'}>{formatRawAmount(2000, order.currency)}</div>
                        <div className={'font-go-text font-semibold text-gray-500 mt-6'}>{t("order_public_page_up_to_50_sales")}</div>
                    </div>
                    <a className={'block'} href={url}><button className={'font-go-button border border-gray-300 rounded-xl w-full h-16 bg-blue-500 text-white text-lg font-bold text-center'}>{t('order_public_page_unlock')}</button></a>
                </div>
                <div className={'w-2/3 sm:1/3 md:w-2/5 lg:w-1/6 my-2 mx-6 space-y-2'}>
                    <div className={'border border-gray-300 rounded-xl w-full h-80 bg-white'}>
                        <div className={'font-go-text mx-auto rounded-full bg-blue-100 w-20 h-20 mt-10 text-blue-500 flex items-center justify-center text-3xl font-medium z-10'}>+70</div>
                        <div className={'font-go-text w-min mx-auto z-20 -mt-4 p-0.5 text-blue-500 font-base text-base bg-gray-100 rounded-md'}>{t('order_public_page_sales')}</div>
                        <div className={'font-go-text text-go-text font-semibold text-5xl mt-6'}>{formatRawAmount(5000, order.currency)}</div>
                        <div className={'font-go-text font-semibold text-gray-500 mt-6'}>{t('order_public_page_up_to_100_sales')}</div>
                    </div>
                    <a className={'block'} href={url}><button className={'border border-gray-300 rounded-xl w-full h-16 bg-blue-500 text-white text-lg font-bold text-center'}>{t('order_public_page_unlock')}</button></a>
                </div>
                <div className={'w-2/3 sm:1/3 md:w-2/5 lg:w-1/6 my-2 mx-6 space-y-2'}>
                    <div className={'border border-gray-300 rounded-xl w-full h-80 bg-white'}>
                        <div className={'mx-auto rounded-full bg-blue-100 w-20 h-20 mt-10'} />
                        <div className={'font-go-text w-min mx-auto z-20 -mt-4 p-0.5 text-blue-500 font-base text-base bg-gray-100 rounded-md invisible'}>{t('order_public_page_sales')}</div>
                        <div className={'font-go-text text-go-text font-semibold text-5xl mt-6'}>{t('order_public_page_more')}</div>
                        <div className={'font-go-text text-go-text font-semibold text-gray-500 mt-6'}>{t('order_public_page_custom_bearing')}</div>
                    </div>
                    <a className={'block'}><button className={'font-go-button border border-gray-300 rounded-xl w-full h-16 bg-white text-lg font-bold text-center'}>{t('order_public_page_contact_us')}</button></a>
                </div>
            </div>
            <div className={'font-go-text text-xl text-gray-500 my-20 text-center px-6'}>{t('order_public_page_footer_text')}</div>
            <ServiceFooter />
            <Pixel app={pixel.app} event={pixel.pages.order_public} />
        </StaticPage>
    );
}
