import React from "react";
import Img from "@gotombola/react-ds/lib/atoms/Img";
import QRCode from "@gotombola/react-ds/lib/atoms/QRCode";
import formatDate from "@gotombola/date/lib/utils/formatDate";
import SummaryItem from "@gotombola/react-ds/lib/atoms/SummaryItem";
import censureContent from "@gotombola/censure/lib/utils/censureContent";
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";
import usePageTypeTranslation from "@gotombola/react-ds/lib/hooks/usePageTypeTranslation";

export function DisplayTicketGameForm({ game, ticket }: DisplayTicketGameFormProps) {
    const {t} = usePageTypeTranslation('public', 'ticket', ticket);
    const date = formatDate(new Date(parseInt(ticket.createdAt)));
    const initials = (!!ticket.lastName && !!ticket.firstName) ? `${ticket.lastName.charAt(0)}. ${ticket.firstName.charAt(0)}.` : '-';
    const ticketPrice = formatRawAmount(ticket.price, ticket.currency);
    const email = censureContent(ticket.email || ticket.owner, 'email');
    const phone = censureContent(ticket.phone, 'phone');

    return (
        <div className={'w-full lg:w-3/4 md:w-2/3 mt-6 sm:mt-12 mx-auto'}>
            <div className={'lg:flex items-center lg:space-x-6'}>
                <Img url={(game.logoImage || {}).url}
                     className={'mx-auto h-16 w-16 p-2 object-contain rounded-xl border border-gray-200'}
                     alt={t('logo_image')}/>
                <div className={'mt-6 lg:mt-0'}>
                    <div
                        className={'font-go-text font-light text-xl text-gray-400 text-center lg:text-left'}>{ticket.gameName}</div>
                    <div
                        className={'font-go-text text-go-text font-bold text-3xl text-center lg:text-left'}>{`${t('ticket')}${ticket.customCode || ticket.code}`}</div>
                </div>
            </div>
            <div className={'flex flex-col-reverse lg:flex-row lg:space-x-8 mt-4 lg:mt-20 mb-10 lg:mb-36'}>
                <div className={'m-auto p-2 bg-gray-100 rounded-lg'}>
                    <QRCode className={'bg-white m-4 w-56 h-56 rounded-xl'} target={ticket.publicPageShortUrl}/>
                </div>
                <div
                    className={'flex-1 flex justify-around flex-wrap mx-4 lg:mx-0 py-4 rounded-lg bg-white'}>
                    <SummaryItem label={t('owner')} value={initials}/>
                    <SummaryItem label={t('email')} value={email}/>
                    <SummaryItem label={t('phone')} value={phone}/>
                    <SummaryItem label={t('date')} value={date}/>
                    <SummaryItem label={t('price')} value={ticketPrice}/>
                    <SummaryItem label={t('status')} status={t('status_valid')}/>
                </div>
            </div>
        </div>
    );
}

export interface DisplayTicketGameFormProps {
    game: any;
    ticket: any;
}

export default DisplayTicketGameForm;
