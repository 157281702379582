import React from 'react';
import {Game, Presult} from "@gotombola/sdk";
import AdZone from '@gotombola/react-ad/lib/organisms/AdZone';

export function PresultAd(props: PresultAdProps) {
    return (
        <AdZone {...props} id={'PRP-1-1'} />
    );
}

export interface PresultAdProps {
    game: Game;
    presult: Presult;
}

export default PresultAd;