import React, {useCallback, useMemo, useState} from "react";
import clsx from "clsx";
import {fromByteArray} from "base64-js";
import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";
import {labellizePersonName} from "@genstackio/person-name";

export function SellerBuyGameForm({ game, sellergroup, workflows }: SellerBuyGameFormProps) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const {t} = useTypeTranslation('game', game);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'seller', {game});
    const length = (((sellergroup || {}).sellers || {}).items || {}).length || 0;
    const [currentGrouping, setCurrentGrouping] = useState(length > 35 && 'default');
    const onClick = useCallback((label) => () => {
        setCurrentGrouping(label);
    }, [setCurrentGrouping]);
    const rawSellers: any[] = (((sellergroup || {}).sellers || {}).items) || [];
    const gameDomain = game.domain || undefined;
    const specificSellerMode = true; // @todo use this to work with non grouped sellers
    const groupings = useMemo(() => {
        return [...rawSellers].reduce((acc: any, seller: any) => {
            if (!!seller.code) {
                query.set('s', seller.code);
            } else {
                query.delete('s');
            }
            const label = seller.lastName.charAt(0);
            const found = acc.find((s: any) => s.label === label);
            const choice = {
                id: seller.code,
                target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
                label: labellizePersonName(seller, game?.sellersSortMode || 'ln-fn', game?.sellersVisibilityMode, rawSellers),
            };
            if (!found) {
                (!query.get('sg') || (specificSellerMode || (seller.sellergroupCode === query.get('sg')))) && acc.push({
                    id: `grp${label}`,
                    target: onClick(label),
                    label: label,
                    children: [choice],
                });
            } else {
                found.children.push(choice);
            }
            return acc;
        }, []);
    }, [rawSellers, query, game, nextStep, urlBuilder]);
    const items = useMemo(() => {
        const its = [...groupings].reduce((acc: any, grouping: any) => {
            ('default' === currentGrouping) ? acc.push(grouping) : acc.push(...grouping.children);
            return acc;
        }, []);
        query.delete('s');
        its.sort((a: any, b: any) => {
            if(!a.id) return 1;
            return a.label.localeCompare(b.label);
        });
        return its;
    }, [query, game, nextStep, gameDomain, groupings, currentGrouping]);

    const [customSeller, setCustomSeller] = useState<any>(undefined)
    const otherItems: any[] = useMemo(() => {
        customSeller && query.set('sc', fromByteArray(Buffer.from(JSON.stringify(customSeller))));
        const its = [{
            id: null,
            target: buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder),
            label: t([`game_buy_seller_other_choice_${gameDomain}`, `game_buy_seller_other_choice_generic`]),
        }];
        query.delete('sc');
        return its;
    }, [customSeller, urlBuilder]);
    const handleChangeLastName = (event: any) => {
        setCustomSeller({...(customSeller || {}), lastName: event.target.value});
    }
    const handleChangeFirstName = (event: any) => {
        setCustomSeller({...(customSeller || {}), firstName: event.target.value});
    }
    const isAlone = !items.length;

    return (
        <div className={'w-full space-y-4'}>
            <ChoiceMenu items={items} center={false} />
            {!isAlone && (
                <div className={'font-go-text text-go-text text-left w-full'}>{t('game_buy_seller_other_name_label')}</div>
            )}
            <div className={clsx('space-y-2 w-full', !isAlone && 'sm:space-y-0 sm:flex sm:space-x-2')}>
                <input
                    onChange={handleChangeLastName}
                    value={(customSeller || {}).lastName || ''}
                    placeholder={t('field_custom_seller_lastname_placeholder')}
                    className={"font-go-text text-go-text sm:flex-1 border border-gray-300 p-3 rounded-md w-full"}
                    autoFocus={isAlone}
                />
                <input
                    onChange={handleChangeFirstName}
                    value={(customSeller || {}).firstName || ''}
                    placeholder={t('field_custom_seller_firstname_placeholder')}
                    className={"font-go-text text-go-text sm:flex-1 border border-gray-300 p-3 rounded-md w-full"}
                />
            </div>
            <ChoiceMenu items={otherItems} />
        </div>
    );
}

export interface SellerBuyGameFormProps {
    game: any;
    workflows: any;
    sellergroup: any;
}

export default SellerBuyGameForm;
