import React, {useMemo} from 'react'
import {useMode} from "../../../utils/common";
import TypePage from '@gotombola/react-ds/lib/pages/TypePage';

export default function PartnerRegisterPage({staticContext: {partner, locale, mode}}: any) {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({pc: partner.code}), [partner.code]);
    const pushProps = useMemo(() => ({mode: partner.status, partner}), [partner, partner.status]);

    return (
        <TypePage type={'partner'} doc={partner} page={'register'} locale={locale}
                  publicPages={publicPages} templates={templates}
                  pixels={pixel} pushs={push}
                  pixelProps={pixelProps} pushProps={pushProps}
        />
    );
}