import React, {useCallback, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import Spinner2 from "@gotombola/react-ds/lib/atoms/Spinner2";
import MainButton from "@gotombola/react-ds/lib/atoms/MainButton";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import GameDrawResults from "@gotombola/react-ds/lib/organisms/GameDrawResults";
import useHttpCallableQuery from "@gotombola/react-ds/lib/hooks/useHttpCallableQuery";
import usePageTypeTranslation from "@gotombola/react-ds/lib/hooks/usePageTypeTranslation";
import GameDrawResultsPodium from "@gotombola/react-ds/lib/organisms/GameDrawResultsPodium";

export function RunDrawLiveGameForm({ game, draw }: RunDrawLiveGameFormProps) {
    const qs = useQueryString();
    const direction = ['asc', 'desc'].includes(qs.get('direction') || '') ? qs.get('direction') : 'asc';
    const descending = 'desc' === direction;
    const {t} = usePageTypeTranslation('draw_live_run', 'game', game);
    const [lastDisplayedWinner, setLastDisplayedWinner] = useState({count: game.liveDrawDisplayedWinnerCount || 0, loading: false, lastChoice: undefined});
    const timestamp = String(new Date().valueOf());
    const [updateLiveDrawDisplayedWinners] = useHttpCallableQuery(`/api/draws-by-game-code-and-public-token-and-key/${game.code}/${game.publicToken}/live-draw-displayed-winners?_=${timestamp}`, 'POST');
    const [fetchDraw, {data, loading}] = useHttpCallableQuery(`/api/draws-by-game-code-and-public-token-and-key/${game.code}/${game.publicToken}/main?_=${timestamp}`);
    draw = (data || {}).draw || draw || undefined;
    useEffect(() => {
        if ((!draw || (draw.status === 'CREATED')) && !loading && (!data || !data.status || (data.status !== 'available'))) {
            const ti = setTimeout(fetchDraw, 2000);

            return () => clearTimeout(ti);
        }
        return;
    }, [draw, data, loading]);

    const moreWinnerFactory = useCallback((n, ref) => () => {
        setLastDisplayedWinner({...lastDisplayedWinner, loading: true, lastChoice: n});
        const count = (lastDisplayedWinner.count + n) > (draw?.winnersCount || 0) ? draw?.winnersCount : (lastDisplayedWinner.count + n);
        setTimeout(() => {
            setLastDisplayedWinner({count: n ? count : 0, loading: false, lastChoice: n});
            if (ref && ref.current) {
                const top = ref.current.offsetTop;
                if (descending) {
                    window.scrollTo({behavior: 'smooth', top: 0, left: 0});
                } else {
                    window.scrollTo(n ? {behavior: 'smooth', top: top - window.innerHeight + 100, left: 0} : {behavior: 'smooth', top: 0, left: 0});
                }
            }
        }, 2000);
    }, [setLastDisplayedWinner, lastDisplayedWinner, descending]);
    const ref = useRef(null);
    const isLoadingNext = lastDisplayedWinner.loading;
    useEffect(() => {
        updateLiveDrawDisplayedWinners({variables: {count: lastDisplayedWinner.count, direction}}).then(() => {});
    }, [lastDisplayedWinner.count, direction])
    const controls = (
        <>
            {!!draw && !!draw.winnersCount && lastDisplayedWinner.count !== draw.winnersCount && <div className={'font-go-text text-go-text font-light text-xl'}>{t('subtitle', {count: draw.winnersCount - lastDisplayedWinner.count})}</div>}
            <div ref={ref} className={'w-full md:px-4 flex flex-col justify-center'}>
                {!!draw && !!draw.winnersCount && lastDisplayedWinner.count !== draw.winnersCount && <div className={'font-go-text text-go-text w-full flex flex-wrap md:flex-nowrap justify-center space-y-2 md:space-y-0 md:space-x-2 lg:space-x-6'}>
                    <MainButton disabled={isLoadingNext} loading={isLoadingNext && (lastDisplayedWinner.lastChoice === 1)} color={'secondary'} label={t(!lastDisplayedWinner.count ? (descending ? 'last_winner_button_label' : 'first_winner_button_label') : (descending ? 'previous_winner_button_label' : 'next_winner_button_label'))} onClick={moreWinnerFactory(1, ref)} />
                    {lastDisplayedWinner.count + 3 <= draw.winnersCount && <MainButton disabled={isLoadingNext} loading={isLoadingNext && (lastDisplayedWinner.lastChoice === 3)} label={t(!lastDisplayedWinner.count ? (descending ? 'last_nth_winners_button_label' : 'first_nth_winners_button_label') : (descending ? 'previous_nth_winners_button_label' : 'next_nth_winners_button_label'), {count: 3})} onClick={moreWinnerFactory(3, ref)} />}
                    {lastDisplayedWinner.count + 5 <= draw.winnersCount && <MainButton disabled={isLoadingNext} loading={isLoadingNext && (lastDisplayedWinner.lastChoice === 5)}  label={t(!lastDisplayedWinner.count ? (descending ? 'last_nth_winners_button_label' : 'first_nth_winners_button_label') : (descending ? 'previous_nth_winners_button_label' : 'next_nth_winners_button_label'), {count: 5})} onClick={moreWinnerFactory(5, ref)} />}
                    {lastDisplayedWinner.count + 10 <= draw.winnersCount && <MainButton disabled={isLoadingNext} loading={isLoadingNext && (lastDisplayedWinner.lastChoice === 10)}  label={t(!lastDisplayedWinner.count ? (descending ? 'last_nth_winners_button_label' : 'first_nth_winners_button_label') : (descending ? 'previous_nth_winners_button_label' : 'next_nth_winners_button_label'), {count: 10})} onClick={moreWinnerFactory(10, ref)} />}
                    <MainButton disabled={isLoadingNext} loading={isLoadingNext && (lastDisplayedWinner.lastChoice === -1)} color={'secondary'} label={t(!lastDisplayedWinner.count ? (descending ? 'last_all_winners_button_label' : 'first_all_winners_button_label') : (descending ? 'previous_all_winners_button_label' : 'next_all_winners_button_label'))} onClick={moreWinnerFactory(draw.winnersCount, ref)} />
                </div>}
            </div>
        </>
    );
    const podiumControls = (
        <>
            {!!draw && !!draw.winnersCount && lastDisplayedWinner.count !== draw.winnersCount && <div className={'font-go-text text-go-text font-light text-xl'}>{t('subtitle_podium', {count: draw.winnersCount - lastDisplayedWinner.count})}</div>}
            <div className={'w-full md:px-4 flex flex-col justify-center'}>
                {!!draw && !!draw.winnersCount && lastDisplayedWinner.count !== draw.winnersCount && <div className={'font-go-text text-go-text w-full flex flex-wrap md:flex-nowrap justify-center space-y-2 md:space-y-0 md:space-x-2 lg:space-x-6'}>
                    <MainButton disabled={isLoadingNext} color={'secondary'} label={t('game_draw_live_page_next_button_label')} onClick={moreWinnerFactory(1, ref)} />
                </div>}
            </div>
        </>
    );

    return (
        <div className={clsx('flex flex-col items-center w-full px-4 md:px-16 sm:pb-4 mb-24 space-y-4', (draw?.winnersCount || 0) <= 3 && 'sm:space-y-4')}>
            <div
                className={clsx('font-go-text max-w-3xl text-go-title text-center', (draw?.winnersCount || 0) <= 3 && 'min-h-[48px]')}>
                {(draw?.winnersCount || 0) === lastDisplayedWinner.count && t('message')}
            </div>
            {descending && !!draw && draw.winnersCount > 3 && controls}
            {!!draw && !!draw.winnersCount && draw.winnersCount > 3 && <GameDrawResults game={game} draw={draw}
                                                                                        from={descending ? (draw.winnersCount - lastDisplayedWinner.count) : 0}
                                                                                        to={descending ? draw.winnersCount : lastDisplayedWinner.count}
                                                                                        searchBar={false} light
                                                                                        searchFocus={false}
                                                                                        obfuscate={false}/>}
            {!!draw && !!draw.winnersCount && draw.winnersCount <= 3 && <GameDrawResultsPodium game={game} draw={draw}
                                                                                               from={descending ? (draw.winnersCount - lastDisplayedWinner.count) : 0}
                                                                                               to={descending ? draw.winnersCount : lastDisplayedWinner.count}
                                                                                               obfuscate={false}
                                                                                               rankLoading={isLoadingNext && (lastDisplayedWinner.lastChoice === 1) ? !descending ? lastDisplayedWinner.count + 1 : draw.winnersCount - lastDisplayedWinner.count : undefined}/>}
            {!!draw && !draw.winnersCount && ('CREATED' === draw.status) && (
                <>
                    <Spinner2/>
                    <div className={'flex flex-col items-center text-center'}>
                        <p className={'font-go-text text-go-text text-md font-bold mb-10'}>{t('game_waiting_for_draw_text')}</p>
                        <p className={'font-go-text text-go-text text-sm font-light mb-1'}>{t('game_waiting_for_draw_disclaimer')}</p>
                        <p className={'font-go-text text-go-text text-sm font-light'}>{t('game_waiting_for_draw_contact')}</p>
                        <p className={'font-go-text text-go-text text-sm font-light mt-4'}>{t('game_waiting_for_draw_completed')}</p>
                    </div>
                </>
            )}
            {!!draw && !draw.winnersCount && ('CREATED' !== draw.status) && (
                <div className={'font-go-text text-go-text font-light text-xl'}>{t('no_winners')}</div>
            )}
            {!draw && (
                <>
                    <Spinner2/>
                    <div className={'flex flex-col items-center text-center'}>
                        <p className={'font-go-text text-go-text text-md font-bold mb-10'}>{t('game_waiting_for_draw_text')}</p>
                        <p className={'font-go-text text-go-text text-sm font-light mb-1'}>{t('game_waiting_for_draw_disclaimer')}</p>
                        <p className={'font-go-text text-go-text text-sm font-light'}>{t('game_waiting_for_draw_contact')}</p>
                        <p className={'font-go-text text-go-text text-sm font-light mt-4'}>{t('game_waiting_for_draw_creation')}</p>
                    </div>
                </>
            )}
            {!descending && !!draw && draw.winnersCount > 3 && controls}
            {(draw?.winnersCount || 0) <= 3 && podiumControls}
            <div ref={ref} className={'w-full md:px-4 flex flex-col justify-center'}>
                {!!lastDisplayedWinner.count && <div className={'w-full flex justify-center'}>
                    <MainButton label={t('restart_label')} onClick={moreWinnerFactory(0, ref)} variant={'filled'}
                                className={'mt-4'}/>
                </div>}
            </div>
        </div>
    );
}

export interface RunDrawLiveGameFormProps {
    game: any;
    draw: any;
}

export default RunDrawLiveGameForm;
