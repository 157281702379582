import React, {useEffect} from "react";
import Spinner from "@gotombola/react-ds/lib/atoms/Spinner";
import GamePaymentBlockedPanel from "./GamePaymentBlockedPanel";
import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import useGameBuyPayments from "../../../hooks/useGameBuyPayments";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function PaymentBuyGameForm({ game, workflows }: PaymentBuyGameFormProps) {
    const { t } = useTypeTranslation('game', game);
    const [items, { blockMode, isPromiseOnly, isPreorderOnly, buildNextStepUrl }] = useGameBuyPayments(game, { t, workflows });

    useEffect(() => {
        if (!blockMode && !items?.length) location.href = buildNextStepUrl();
    }, [items, buildNextStepUrl, blockMode]);

    return (
        <>
            {!!items.length && <ChoiceMenu items={items} />}
            {!blockMode && !items.length && <Spinner />}
            {blockMode && <GamePaymentBlockedPanel game={game} />}
        </>
    );
}

export interface PaymentBuyGameFormProps {
    game: any;
    workflows: any;
}

export default PaymentBuyGameForm;
