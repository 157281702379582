import React, {useCallback, useMemo} from 'react'
import {useMode} from "../../../utils/common";
import TypePage from '@gotombola/react-ds/lib/pages/TypePage';
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import {useParams} from "react-router-dom";

const GameGodfathersRankingPage = editablePage('game', [], ({staticContext: {game, locale, mode}}: any) => {
    const {pixel, push, publicPages, templates} = useMode(mode);
    const { period } = useParams<{ period?: string }>();

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);
    const extraFn = useCallback(() => {
        return { period };
    }, [period]);

    return (
        <GamePageWrapper game={game} locale={locale}>
            <TypePage type={'game'} doc={game} page={'godfathers_ranking'} locale={locale}
                      publicPages={publicPages} templates={templates}
                      pixels={pixel} pushs={push}
                      pixelProps={pixelProps} pushProps={pushProps}
                      extraFn={extraFn}
            />
        </GamePageWrapper>
    );
});

export default GameGodfathersRankingPage;
