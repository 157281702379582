import React, {useMemo} from 'react'
import {useMode} from "../../../utils/common";
import TypePage from '@gotombola/react-ds/lib/pages/TypePage';

const pixelProps = {};

export default function TenantPublicPage({staticContext: {tenant, locale, mode}}: any) {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pushProps = useMemo(() => ({mode: tenant.status, tenant}), [tenant, tenant.status]);

    return (
        <TypePage type={'tenant'} doc={tenant} page={'public'} locale={locale}
                  publicPages={publicPages} templates={templates}
                  pixels={pixel} pushs={push}
                  pixelProps={pixelProps} pushProps={pushProps}
        />
    );
}