export default {
    game_public: 'a1.x1',
    game_draw_results: 'a1.x50',
    game_draw_live: 'a1.x52',
    game_draw_live_run: 'a1.x53',
    game_draw_live_direction: 'a1.x54',
    game_terms_and_conditions: 'a1.x20',
    game_legal: 'a1.x22',
    game_communication: 'a1.x23',
    game_inbox: 'a1.x24',
    game_share: 'a1.x19',
    game_sellergroup_registration_welcome: 'a1.x55',
    game_sellergroup_registration_team: 'a1.x56',
    game_sellergroup_registration_owner: 'a1.x57',
    game_sellergroup_registration_summary: 'a1.x58',
    game_leaderboard: 'a1.x19001',
    game_files: 'a1.x19002',
    game_account_link_request: 'a1.x19003',
    game_godfathers_ranking: 'a1.x19004',
}