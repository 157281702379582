import React, {useMemo} from 'react';
import {useMode} from "../../../utils/common";
import Pixel from "@gotombola/react-ds/lib/atoms/Pixel";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";
import DisplayTicketGameForm from "../molecules/DisplayTicketGameForm";
import PushNotification from "@gotombola/react-ds/lib/atoms/PushNotification";

export const TicketPublicPage = editablePage('game', [], ({staticContext: {ticket, game, locale, mode}}: any) => {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);

    return (
        <GamePageWrapper game={game} locale={locale}>
            <TypePage type={'game'} doc={game} page={'ticket_public'} locale={locale}
                      publicPages={publicPages} templates={templates}
                      pixels={pixel} pushs={push}
                      pixelProps={pixelProps} pushProps={pushProps}
                      noindex
            >
                <DisplayTicketGameForm game={game} ticket={ticket} />
                <Pixel app={pixel.app} event={pixel.pages.ticket_public} />
                <PushNotification app={push.app} type={push.pages.ticket_public} mode={game.status} game={game} ticket={ticket} />
            </TypePage>
        </GamePageWrapper>
    );
});

export default TicketPublicPage;
