export default {
    game_buy_quantity: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_quantity_title",
                },
                "common_message": {
                    "attribute": "buyQuantityMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_custom_quantity: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_custom_quantity_title",
                },
                "common_message": {
                    "attribute": "buyCustomQuantityMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_owner: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_owner_title",
                },
                "common_message": {
                    "attribute": "buyOwnerMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_bonus: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_bonus_title",
                },
                "common_message": {
                    "attribute": ["bonusMessage", "buyBonusMessage"],
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_payment: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_payment_title",
                },
                "common_message": {
                    "attribute": "buyPaymentMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_beneficiary: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_beneficiary_title",
                },
                "common_message": {
                    "attribute": "buyBeneficiaryMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_project: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_project_title",
                },
                "common_message": {
                    "attribute": "buyProjectMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_request: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_request_title",
                },
                "common_message": {
                    "attribute": "buyRequestMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_privacy: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_privacy_title",
                },
                "common_message": {
                    "attribute": "buyPrivacyMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_donation: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_donation_title",
                },
                "common_message": {
                    "attribute": "buyDonationMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_custom: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_custom_title",
                },
                "common_message": {
                    "attribute": "buyCustomMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_sellergroup_groupings: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_seller_group_groupings_title",
                },
                "common_message": {
                    "attribute": "buySellerGroupGroupingsMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_sellergroup: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_seller_group_title",
                },
                "common_message": {
                    "attribute": "buySellerGroupMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_seller: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_seller_title",
                },
                "common_message": {
                    "attribute": "buySellerMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
    game_buy_external_payment: {
        "default": {
            "dsn":"header=game_header;main=common_dynbody,common_title,common_message,type_demo_banner;footer=game_footer,common_tenant_footer",
            "layout": "funnel",
            "params": {
                "common_title": {
                    "title": "game_buy_external_payment_title",
                },
                "common_message": {
                    "attribute": "buyExternalPaymentMessage",
                },
                "game_header": {
                    "light": true
                },
            },
        },
    },
}