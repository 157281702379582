import React, {useCallback, useMemo} from 'react';
import {useMode} from "../../../utils/common";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";
import GamePageWrapper from "@gotombola/react-ds/lib/organisms/GamePageWrapper";
import editablePage from "@gotombola/react-ds/lib/hocs/editablePage";

const SellerPublicPage = editablePage('seller', [], ({staticContext: {game, seller, locale, mode}}: any) => {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);
    const extraFn = useCallback(() => ({game}), [game]);

    return (
        <GamePageWrapper game={game} locale={locale}>
            <TypePage type={'seller'} doc={seller} extraFn={extraFn} page={'public'} locale={locale}
                      publicPages={publicPages} templates={templates}
                      pixels={pixel} pushs={push}
                      pixelProps={pixelProps} pushProps={pushProps}
            />
        </GamePageWrapper>
    );
});

export default SellerPublicPage;
