import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import React, {useCallback, useMemo} from "react";
import useGameBuyBonuses from "../../../hooks/useGameBuyBonuses";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import clsx from "clsx";

const colors = 'border-go-choice-secondary bg-go-choice-secondary text-go-choice-primary hover:bg-go-choice-primary hover:text-go-choice-secondary';

export function BonusBuyGameForm({ game, workflows }: BonusBuyGameFormProps) {
    const {t} = useTypeTranslation('game', game);
    const bonuses = useGameBuyBonuses(game, { t, workflows });
    const {items, skip} = useMemo(() => (bonuses || []).reduce((acc, item) => {
        if (item?.isSkip) {
            acc.skip = item;
        } else {
            acc.items.push(item);
        }
        return acc;
    }, { items: [], skip: undefined} as { items: any[], skip?: any }), [bonuses])
    const onNext = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (skip?.target) {
            location.href = skip?.target;
        }
    }, [skip]);
    return (
        <>
            <ChoiceMenu items={items}/>
            {!!skip && <button
                type={"button"}
                onClick={onNext}
                className={clsx("mt-10 font-go-button w-full p-3 border disabled:border-gray-300 disabled:text-white disabled:bg-gray-300 rounded-md text-xl", colors)}
            >
                {skip?.label}
            </button>}
        </>
    );
}

export interface BonusBuyGameFormProps {
    game: any;
    workflows: any;
}

export default BonusBuyGameForm;
