import * as pages from './pages';

const extraRouteList = [
    {path: '/', exact: false, component: pages.not_found},
];

export default {configure: ({data}: any) => {
    const routeList = data?.game?.pages?.items.reduce((acc: any, p: any) => {
        acc.push({name: p.code, path: p.key, component: pages.generic, exact: true});
        return acc;
    }, []) || [];

    return {extraRouteList, routeList, pages};
}};