import React, {useCallback, useMemo, useState} from "react";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import BuiltForm from "../../../organisms/BuiltForm";
import Text from "@gotombola/react-ds/lib/atoms/Text";
import formatRawAmount from "@gotombola/amount/lib/utils/formatRawAmount";
import {buildSaleItems} from "@gotombola/sale-items-builder";

const defaultDsns = {
    donation: 'donation*',
    default: 'ticketQuantity*',
};

const defaultValue = {
    ticketQuantity: 1,
    donation: 1,
};

export function CustomQuantityBuyGameForm({ game, workflows }: CustomQuantityBuyGameFormProps) {
    const {t} = useTypeTranslation('game', game);
    const urlBuilder = useBuildUrlFor('game', game, false);
    const [quantity, setQuantity] = useState(1);
    const onChange = useCallback((data: any) => {
        setQuantity(Number('donation' === game?.type ? data['donation'] : data['ticketQuantity'] || 0));
    }, [setQuantity, game?.type]);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'custom_quantity', {game});
    const handleSubmit = useCallback((data: any) => {
        (game?.type !== 'donation') && data.ticketQuantity && query.set('q', data.ticketQuantity);
        (game?.type === 'donation') && data.donation && query.set('q', data.donation);
        const url = buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder);
        url && (location.href = url);
    }, [query, nextStep, game, urlBuilder]);
    const price = useMemo(() => {
        const [_, __, { paymentAmount }] = buildSaleItems('', { tickets: quantity, donation: 0, ticketPriceAmount: game.ticketPriceAmount}, game.packs?.items) as any;
        return paymentAmount;
    }, [game.ticketPriceAmount, game.packs, quantity]);

    const maxQuantity = useMemo(() => {
        if ('donation' === game?.type) return 10000;
        return 500;
    }, [game?.type]);

    const valid = useMemo(() => quantity >= 1 && quantity <= maxQuantity, [quantity, maxQuantity]);
    const payable = useMemo(() => game.ticketPriceAmount > 0, [game.ticketPriceAmount]);

    const amount = (game?.type === 'donation') ? formatRawAmount(quantity * 100, game?.ticketPriceCurrency) : formatRawAmount(price, game?.ticketPriceCurrency);
    const maxAmount = (game?.type === 'donation') ? formatRawAmount(maxQuantity * 100, game?.ticketPriceCurrency) : formatRawAmount(maxQuantity * (game?.ticketPriceAmount || 0), game?.ticketPriceCurrency);

    const dsn = useMemo(() => defaultDsns[game?.type || 'default'] || defaultDsns['default'], [game?.type]);

    return (
        <div className={'w-full'}>
            <BuiltForm onSubmit={handleSubmit} dsn={dsn} defaultValue={defaultValue} t={t}
                       description={t('game_buy_custom_quantity_page_text', {max: maxQuantity, maxAmount})}
                       onChange={onChange}
                       buttonLabel={t('game_buy_custom_quantity_page_submit_label')}
            >
                {payable && (<>
                    {valid && (<>
                        <div className={'flex flex-col gap-1 mt-2'}>
                            <Text value={t('game_buy_custom_quantity_page_amount', { count: quantity })} className={'font-bold text-center'} />
                            <Text value={amount} className={'font-bold text-center text-2xl'} />
                        </div>
                        <Text value={t('game_buy_custom_quantity_page_amount_explain')} className={'text-center sm:text-justify'} />
                    </>)}
                    {!valid && (<>
                        <Text value={t('game_buy_custom_quantity_page_amount_invalid', {maxAmount})} className={'text-center sm:text-justify text-red-500'} bold />
                    </>)}
                </>)}
            </BuiltForm>
        </div>
    )
}

export interface CustomQuantityBuyGameFormProps {
    game: any;
    workflows: any;
}

export default CustomQuantityBuyGameForm;
