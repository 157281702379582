import React from 'react';
import {Game, Sale} from "@gotombola/sdk";
import AdZone from '@gotombola/react-ad/lib/organisms/AdZone';

export function SaleAd(_: SaleAdProps) {
    return (
        <AdZone id={'SP-1-1'} />
    );
}

export interface SaleAdProps {
    game: Game;
    sale: Sale;
}

export default SaleAd;