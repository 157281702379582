import React, {useCallback, useMemo, useState} from "react";
import clsx from "clsx";
import Text from "@gotombola/react-ds/lib/atoms/Text";
import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import useQueryString from "@gotombola/react-ds/lib/hooks/useQueryString";
import useBuildUrlFor from "@gotombola/react-ds/lib/hooks/useBuildUrlFor";
import useWorkflowStep from "@gotombola/react-ds/lib/hooks/useWorkflowStep";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";
import buildGameNextStepUrl from "@gotombola/react-ds/lib/utils/buildGameNextStepUrl";

const privacyData = {
    rgpd: {
        subtitle: 'game_buy_privacy_page_subtitle', items: [
            {label: 'game_buy_privacy_checkbox_organisation_label', defaultValue: false, code: 'x01'},
            {label: 'game_buy_privacy_checkbox_gotombola_label', defaultValue: false, code: 'x02'},
        ]
    },
}
const colors = 'border-go-choice-secondary bg-go-choice-secondary text-go-choice-primary hover:bg-go-choice-primary hover:text-go-choice-secondary';

export function PrivacyBuyGameForm({ game, workflows }: PrivacyBuyGameFormProps) {
    const urlBuilder = useBuildUrlFor('game', game, false);
    const {t} = useTypeTranslation('game', game);
    const query = useQueryString();
    const {nextStep} = useWorkflowStep(workflows, 'sale', 'privacy', {game});
    const [value, onChange] = useState({x01: false, x02: false});
    const handleSubmit = useCallback(() => {
        value.x01 && query.set('x01', (+ value.x01).toString());
        value.x02 && query.set('x02', (+ value.x02).toString());
        const url = buildGameNextStepUrl(game, nextStep, query, undefined, undefined, urlBuilder);
        url && (location.href = url);
    }, [query, nextStep, game, urlBuilder]);
    const items = useMemo(() => privacyData[game.privacyShield]?.items?.map((x: any) => {
        return {
            label: t(x.label),
            value: x.defaultValue,
            checkbox: true,
            code: x.code,
        };
    }) || [], [privacyData, t, game.privacyShield]);

    return (
        <div className={'w-full space-y-4'}>
            <Text light className={'w-full text-left'}>{t(privacyData[game.privacyShield || '']?.subtitle || '')}</Text>
            <ChoiceMenu items={items} value={value} onChange={onChange}/>
            <button
                type={"button"}
                onClick={handleSubmit}
                className={clsx("font-go-button w-full p-3 border rounded-md text-xl", colors)}
            >
                {t('game_buy_privacy_btn_label')}
            </button>
        </div>
    );
}

export interface PrivacyBuyGameFormProps {
    game: any;
    workflows: any;
}

export default PrivacyBuyGameForm;
