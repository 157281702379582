import loadable from '@loadable/component';

export const game_public = loadable(() => import('./GamePublicPage'));
export const game_results = loadable(() => import('./GameDrawResultsPage'));
export const game_draw_live = loadable(() => import('./GameDrawLivePage'));
export const game_draw_live_run = loadable(() => import('./GameDrawLiveRunPage'));
export const game_draw_live_direction = loadable(() => import('./GameDrawLiveDirectionPage'));
export const game_share = loadable(() => import('./GameSharePage'));
export const game_terms_and_conditions = loadable(() => import('./GameTermsAndConditionsPage'));
export const game_legal = loadable(() => import('./GameLegalPage'));
export const game_files = loadable(() => import('./GameFilesPage'));
export const game_inbox = loadable(() => import('./GameInboxPage'));
export const game_leaderboard = loadable(() => import('./GameLeaderboardPage'));
export const game_communication = loadable(() => import('./GameCommunicationPage'));
export const game_sellergroup_registration_welcome = loadable(() => import('./GameSellergroupRegistrationWelcomePage'));
export const game_sellergroup_registration_team = loadable(() => import('./GameSellergroupRegistrationTeamPage'));
export const game_sellergroup_registration_owner = loadable(() => import('./GameSellergroupRegistrationOwnerPage'));
export const game_sellergroup_registration_summary = loadable(() => import('./GameSellergroupRegistrationSummaryPage'));
export const game_account_link_request = loadable(() => import('./GameAccountLinkRequestPage'));
export const game_godfathers_ranking = loadable(() => import('./GameGodfathersRankingPage'));