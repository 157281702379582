import React from "react";
import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import useGameBuySellergroups from "../../../hooks/useGameBuySellergroups";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function SellergroupBuyGameForm({ game, workflows }: SellergroupBuyGameFormProps) {
    const {t} = useTypeTranslation('game', game);

    const items = useGameBuySellergroups(game, { t, workflows });

    return (
        <ChoiceMenu items={items} />
    );
}

export interface SellergroupBuyGameFormProps {
    game: any;
    workflows: any;
}

export default SellergroupBuyGameForm;
