import React from "react";
import Text from "@gotombola/react-ds/lib/atoms/Text";
import ChoiceMenu from "@gotombola/react-ds/lib/molecules/ChoiceMenu";
import useGamePromisePayments from "../../../hooks/useGamePromisePayments";
import useTypeTranslation from "@gotombola/react-ds/lib/hooks/useTypeTranslation";

export function PaymentPromiseGameForm({ game, promise }: PaymentPromiseGameFormProps) {
    const {t} = useTypeTranslation('game', game);

    const items = useGamePromisePayments(promise, game, { t });
    let titleKey: string;

    switch (true) {
        case 'CREATED' === promise.status && !!items.length: titleKey = 'promise_payment_page_title'; break;
        case 'CREATED' === promise.status && !items.length: titleKey = 'promise_payment_no_payment_means_page_title'; break;
        case 'COMPLETED' === promise.status: titleKey = 'promise_payment_page_title_completed'; break;
        default: titleKey = 'promise_payment_page_title_unknown'; break;
    }

    return (
        <>
            {'CREATED' === promise.status && !!items.length && <ChoiceMenu items={items} />}
            {'CREATED' === promise.status && !items.length && <Text>No payment means available.</Text>}
            {'COMPLETED' === promise.status && <Text>Promise already completed.</Text>}
            {('CREATED' !== promise.status) && ('COMPLETED' !== promise.status) && <Text>Promise not available.</Text>}
        </>
    );
}

export interface PaymentPromiseGameFormProps {
    game: any;
    promise: any;
}

export default PaymentPromiseGameForm;
