import React, {useCallback, useMemo} from 'react';
import {useMode} from "../../../utils/common";
import TypePage from "@gotombola/react-ds/lib/pages/TypePage";

export default function SponsoringPublicPage({staticContext: {game, sponsoring, locale, mode}}: any) {
    const {pixel, push, publicPages, templates} = useMode(mode);

    const pixelProps = useMemo(() => ({gc: game.code}), [game.code]);
    const pushProps = useMemo(() => ({mode: game.status, game}), [game, game.status]);
    const extraFn = useCallback(() => ({game}), [game]);

    return (
        <TypePage type={'sponsoring'} doc={sponsoring} extraFn={extraFn} page={'public'} locale={locale}
                  publicPages={publicPages} templates={templates}
                  pixels={pixel} pushs={push}
                  pixelProps={pixelProps} pushProps={pushProps}
        />
    );
}